import { useWindowContext } from "@lib/context/window";
import { useMobile } from "@lib/hooks/useMobile";
import { MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { MarqueeElement, MarqueeWrapper } from "./Marquee.style";

const checkIfElementIsInViewport = (element: HTMLDivElement) => {
	if (!element) return false;
	return (element.offsetParent !== null);
};

interface MarqueeProps {
	children: ReactNode;
	overlayElementSelector?: string | null;
	shouldWrap?: boolean;
	shouldCheckPresence?: boolean;
	maxWidth?: string;
}

/**
 * Marquee component
 * @param {ReactNode} children - The content to be rendered inside the marquee
 * @param {string} overlayElementSelector - The selector for the element that should be checked for overlap
 * @param {boolean} shouldWrap - Whether the marquee should wrap its content
 * @param {boolean} shouldCheckPresence - Whether the marquee should check if it's in the viewport
 * @returns {ReactNode} - The marquee component
 */
const Marquee = ({ children, overlayElementSelector, shouldWrap = true, shouldCheckPresence = false, maxWidth }: MarqueeProps) => {
	let timeout: NodeJS.Timeout | null = null;
	const { width } = useWindowContext();
	const [marqueeParentWidth, setMarqueeParentWidth] = useState<number>(1);
	const [marqueeElementWidth, setMarqueeElementWidth] = useState<number>(0);
	const [shouldForceMarquee, setShouldForceMarquee] = useState<boolean>(false);
	const { isMobileDevice } = useMobile();
	const marqueeParentElement = useRef() as MutableRefObject<HTMLDivElement>;
	const marqueeElement = useRef() as MutableRefObject<HTMLParagraphElement>;

	const getMarqueeElementWidths = () => {
		const marqueeParentElementBounds = marqueeParentElement.current?.getBoundingClientRect();
		const marqueeElementBounds = marqueeElement.current?.getBoundingClientRect();
		if (marqueeParentElementBounds) setMarqueeParentWidth(marqueeParentElementBounds.width);
		if (marqueeElementBounds) setMarqueeElementWidth(marqueeElementBounds.width);
	};

	useEffect(() => { getMarqueeElementWidths(); }, [width, children]);

	const isInViewport = shouldCheckPresence ? checkIfElementIsInViewport(marqueeParentElement.current) : true;
	const shouldRollMarquee = (shouldForceMarquee || !!(width && (marqueeElementWidth > marqueeParentWidth))) && isInViewport;

	const doElementsOverlap = useCallback((elementMarquee: Element, elementOverlay: Element) => {
		if (!elementMarquee || !elementOverlay) return false;
		const elementMarqueeBounds = elementMarquee.getBoundingClientRect();
		const elementOverlayBounds = elementOverlay.getBoundingClientRect();

		return elementOverlayBounds.left < elementMarqueeBounds.right;
	}, [width]);

	const checkForMarqueeOverlap = () => {
		if (!overlayElementSelector) { return; }
		const overlayElement = document.querySelector(overlayElementSelector) as Element;
		if (!overlayElement) { return; }

		const animationFrame = requestAnimationFrame(() => {
			timeout = setTimeout(() => {
				const isOverlapping = doElementsOverlap(
					marqueeElement.current,
					document.querySelector(overlayElementSelector) as Element,
				);

				setShouldForceMarquee(isOverlapping);
			}, 250); // Wait for the overlay CSS transition to finish
		});

		return () => cancelAnimationFrame(animationFrame);
	};

	const clearCheckForMarqueeOverlap = () => {
		setShouldForceMarquee(false);
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
	};

	if (!isMobileDevice) {
		return (
			<MarqueeWrapper
				data-testid="marquee-parent"
				$isActive={shouldRollMarquee}
				$elementWidth={marqueeElementWidth}
				$shouldWrap={shouldWrap}
				ref={marqueeParentElement}
				onMouseEnter={checkForMarqueeOverlap}
				onMouseLeave={clearCheckForMarqueeOverlap}
				$maxWidth={maxWidth}
			>
				<MarqueeElement ref={marqueeElement}>
					{children}
				</MarqueeElement>
				{shouldRollMarquee && (
					<MarqueeElement data-testid="marquee-pseudo">
						{children}
					</MarqueeElement>
				)}
			</MarqueeWrapper>
		);
	}

	return (
		<MarqueeElement ref={marqueeElement}>
			{children}
		</MarqueeElement>
	);
};

export default Marquee;
