import { NextPageWithLayout } from "@bptypes/layout";
import ErrorBoundary from "@components/errorBoundary";
import { AFFILIATE_TRACKING, DEFAULT_LOCALE, nextAuthRefreshInterval } from "@lib/constants";
import NO_INDEX_PAGE_URLS from "@lib/constants/no-index-pages";
import { CartProvider } from "@lib/context/cart";
import { ChartsProvider } from "@lib/context/charts";
import { CollectionProvider } from "@lib/context/collection";
import { MyBeatportProvider } from "@lib/context/my-beatport";
import { PlayerProvider } from "@lib/context/player";
import { LocalSessionProvider } from "@lib/context/session";
import { TwitchLiveStreamProvider } from "@lib/context/twitchLiveStream";
import { WindowProvider } from "@lib/context/window";
import { shouldAllowPAPTracking } from "@lib/utils/shouldAllowPAPTracking";
import "@styles/globals.css";
import { theme } from "@styles/theme";
import {
	Hydrate,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getConfig } from "config";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import Script from "next/script";
import { parseCookies } from "nookies";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import MainLayout from "src/layouts/MainLayout/MainLayout";
import { ThemeProvider } from "styled-components";
import "../polyfills";

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
	isPageExplicit: boolean;
};

const config = getConfig();
const isDev = config.ENV_NAME === "development";

const ClientPlayer = dynamic(
	() => import("@components/layouts/player/Player"),
	{
		ssr: false,
	},
);

const CANONICAL = "canonical";
const ALTERNATE = "alternate";

function MyApp({
	Component,
	pageProps: { session, ...pageProps },
	isPageExplicit,
}: AppPropsWithLayout) {
	const router = useRouter();
	const { pathname, asPath, query, locales, locale } = router;
	const allowPAPTracking = typeof window !== "undefined" ? shouldAllowPAPTracking() : false;

	// Set PAP Affiliate ID in session storage to persist value
	// in case the url param is cleared before click tracking is sent
	let AffiliateID = "";
	if (typeof window !== "undefined") {
		const storedAffiliateID = sessionStorage.getItem("PAP-affiliate-id");
		if (query.a_aid) {
			sessionStorage.setItem("PAP-affiliate-id", query.a_aid.toString());
			AffiliateID = query.a_aid.toString();
		} else if (
			storedAffiliateID !== null &&
			typeof storedAffiliateID === "string"
		) {
			AffiliateID = storedAffiliateID;
		}
	}

	useEffect(() => {
		const cookies = parseCookies();
		const localeCookie = cookies.NEXT_LOCALE;
		const supportedLocales = locales;
		const currentLocale = locale;
		if (
			localeCookie &&
			supportedLocales?.includes(localeCookie) &&
			currentLocale !== localeCookie
		) {
			router.push({ pathname, query }, asPath, { locale: localeCookie });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	NProgress.configure({ showSpinner: false });
	Router.events.on("routeChangeStart", () => {
		NProgress.start();
	});

	Router.events.on("routeChangeComplete", () => {
		NProgress.done(false);
	});

	Router.events.on("routeChangeError", () => {
		NProgress.done(false);
	});

	const getLayout =
		Component.getLayout ??
		((page: React.ReactNode) => (
			<MainLayout hasDesktopSidebar={Component.hasSidebar}>{page}</MainLayout>
		));

	const [queryClient] = useState(() => new QueryClient());
	queryClient.setDefaultOptions({
		queries: {
			staleTime: 300000, // 5 minutes
			cacheTime: 300000, // 5 minutes
			retry: 5,
			retryDelay: (attemptIndex) => {
				if (attemptIndex > 2) {
					return Math.floor(Math.random() * 30) * 1000; // jitter last 2 attempts
				} else {
					return Math.min(1000 * 2 ** attemptIndex, 30000); // exponential backoff first 3 attempts
				}
			},
			refetchOnWindowFocus: false,
		},
	});

	const QualtricsDiv = () => {
		return <div id="ZN_bmy8XLdqGwUhezs"></div>;
	};

	return (
		<>
			<Head>
				{router.locales?.map((localeOption) => {
					const isEnglish = localeOption === DEFAULT_LOCALE;
					const pathWithLocale = isEnglish ? `${router.asPath}` : `/${localeOption}${router.asPath}`;
					return (
						<link
							key={isEnglish ? CANONICAL : localeOption}
							rel={isEnglish ? CANONICAL : ALTERNATE}
							hrefLang={localeOption}
							href={`${pathWithLocale}`}
						/>
					);
				})}
				{
					isPageExplicit && (
						<meta name="robots" content="noindex" />
					)
				}
			</Head>
			<SessionProvider
				session={session}
				// Re-fetch session on interval
				refetchInterval={nextAuthRefreshInterval}
				// Re-fetches session when window is focused
				refetchOnWindowFocus={false}
			>
				<LocalSessionProvider>
					<QueryClientProvider client={queryClient}>
						<Hydrate state={pageProps.dehydratedState}>
							<ThemeProvider theme={theme}>
								<CartProvider>
									<ErrorBoundary>
										<CollectionProvider>
											<ChartsProvider>
												<MyBeatportProvider>
													<PlayerProvider>
														<WindowProvider>
															<TwitchLiveStreamProvider>
																{getLayout(<Component {...pageProps} />)}
																<QualtricsDiv />
																<ClientPlayer />
															</TwitchLiveStreamProvider>
														</WindowProvider>
													</PlayerProvider>
												</MyBeatportProvider>
											</ChartsProvider>
										</CollectionProvider>
									</ErrorBoundary>
								</CartProvider>
							</ThemeProvider>
							{isDev && <ReactQueryDevtools initialIsOpen={false} />}
						</Hydrate>
					</QueryClientProvider>
				</LocalSessionProvider>

				{allowPAPTracking && (
					<Script id="pap_click-tracking" strategy="afterInteractive">
						{`
							if(typeof PostAffTracker !== "undefined") {
								PostAffTracker.setAccountId('${AFFILIATE_TRACKING.accountId}');
								if('${AffiliateID}' !== "") window.AffiliateID = '${AffiliateID}';
								try {
									PostAffTracker.track();
								} catch (err) { }
							}
							`}
					</Script>
				)}
			</SessionProvider>
		</>
	);
}

/*
 * This "passthrough" getInitialProps disables Automatic Static Optimization:
 * https://nextjs.org/docs/messages/opt-out-auto-static-optimization
 *
 * This is necessary if we want to our custom _document.tsx to always render on the
 * server.
 */
MyApp.getInitialProps = async ({
	Component,
	ctx,
}: {
	Component: any;
	ctx: any;
}) => {
	let pageProps = {};
	if (Component.getInitialProps) {
		pageProps = await Component.getInitialProps(ctx);
	}

	let isPageExplicit = false;

	if (NO_INDEX_PAGE_URLS.includes(ctx.asPath)) {
		isPageExplicit = true;
	}

	return {
		pageProps,
		isPageExplicit,
	};
};

export default appWithTranslation(MyApp);
