export const ROUTES = {
	SUBSCRIPTIONS: "/subscriptions",
	BILLING: "/subscriptions/billing",
	CANCEL: "/subscriptions/cancel",
	CHANGE: "/subscriptions/change",
};

export const PLANS = {
	BP: "bp_basic",
	BP_ANNUAL: "bp_basic_annual",
	BP_EMPLOYEE: "bp_basic_employee",
	BP_PARTNER: "bp_basic_partners",

	BP_LINK: "bp_link",
	BP_LINK_ANNUAL: "bp_link_annual",
	BP_LINK_EMPLOYEE: "bp_link_employee",
	BP_LINK_PARTNER: "bp_link_partners",

	BP_PRO: "bp_link_pro",
	BP_PRO_ANNUAL: "bp_link_pro_annual",
	BP_PRO_EMPLOYEE: "bp_link_pro_employee",
	BP_PRO_PARTNER: "bp_link_pro_partners",
	BP_PRO_AMBASSADOR: "bp_link_pro_ambassador",

	BP_PRO_PLUS: "bp_link_pro_plus",
	BP_PRO_PLUS_EMPLOYEE: "bp_link_pro_plus_employee",
	BP_PRO_PLUS_PARTNER: "bp_link_pro_plus_partners",
	BP_PRO_PLUS_AMBASSADOR: "bp_link_pro_plus_ambassador",
};

export const AVAILABLE_PLANS = [
	PLANS.BP,
	PLANS.BP_ANNUAL,
	PLANS.BP_LINK,
	PLANS.BP_LINK_ANNUAL,
	PLANS.BP_PRO,
	PLANS.BP_PRO_ANNUAL,
];

export const MONTHLY_PLANS = [
	PLANS.BP,
	PLANS.BP_LINK,
	PLANS.BP_PRO,
];

export const ANNUAL_PLANS = [
	PLANS.BP_ANNUAL,
	PLANS.BP_LINK_ANNUAL,
	PLANS.BP_PRO_ANNUAL,
];

export const PLANS_WITH_FLAC_STREAMING = [
	PLANS.BP_PRO,
	PLANS.BP_PRO_ANNUAL,
	PLANS.BP_PRO_PARTNER,
	PLANS.BP_PRO_PLUS_AMBASSADOR,
	PLANS.BP_PRO_PLUS_EMPLOYEE,
];

export const BEST_VALUE_PLANS = [
	PLANS.BP_LINK,
	PLANS.BP_LINK_ANNUAL,
];

export const PLAN_ORDER = {
	[PLANS.BP]: 1,
	[PLANS.BP_ANNUAL]: 1,
	[PLANS.BP_EMPLOYEE]: 1,
	[PLANS.BP_PARTNER]: 1,

	[PLANS.BP_LINK]: 2,
	[PLANS.BP_LINK_ANNUAL]: 2,
	[PLANS.BP_LINK_EMPLOYEE]: 2,
	[PLANS.BP_LINK_PARTNER]: 2,

	[PLANS.BP_PRO]: 3,
	[PLANS.BP_PRO_ANNUAL]: 3,
	[PLANS.BP_PRO_EMPLOYEE]: 3,
	[PLANS.BP_PRO_PARTNER]: 3,
	[PLANS.BP_PRO_AMBASSADOR]: 3,

	[PLANS.BP_PRO_PLUS]: 4,
	[PLANS.BP_PRO_PLUS_EMPLOYEE]: 4,
	[PLANS.BP_PRO_PLUS_PARTNER]: 4,
	[PLANS.BP_PRO_PLUS_AMBASSADOR]: 4,
};

export const PLAN_SHORT_NAMES = {
	[PLANS.BP]: "Subscriptions.Plans.Essential",
	[PLANS.BP_ANNUAL]: "Subscriptions.Plans.Essential",
	[PLANS.BP_EMPLOYEE]: "Subscriptions.Plans.EssentialEmployee",
	[PLANS.BP_PARTNER]: "Subscriptions.Plans.EssentialPartner",

	[PLANS.BP_LINK]: "Subscriptions.Plans.Advanced",
	[PLANS.BP_LINK_ANNUAL]: "Subscriptions.Plans.Advanced",
	[PLANS.BP_LINK_EMPLOYEE]: "Subscriptions.Plans.AdvancedEmployee",
	[PLANS.BP_LINK_PARTNER]: "Subscriptions.Plans.AdvancedPartner",

	[PLANS.BP_PRO]: "Subscriptions.Plans.Professional",
	[PLANS.BP_PRO_ANNUAL]: "Subscriptions.Plans.Professional",
	[PLANS.BP_PRO_EMPLOYEE]: "Subscriptions.Plans.ProfessionalEmployee",
	[PLANS.BP_PRO_PARTNER]: "Subscriptions.Plans.ProfessionalPartner",
	[PLANS.BP_PRO_AMBASSADOR]: "Subscriptions.Plans.ProfessionalAmbassador",

	[PLANS.BP_PRO_PLUS]: "Subscriptions.Plans.LinkPro",
	[PLANS.BP_PRO_PLUS_EMPLOYEE]: "Subscriptions.Plans.ProfessionalEmployee",
	[PLANS.BP_PRO_PLUS_PARTNER]: "Subscriptions.Plans.ProfessionalPartner",
	[PLANS.BP_PRO_PLUS_AMBASSADOR]: "Subscriptions.Plans.ProfessionalAmbassador",
};

export const PLAN_DESCRIPTIONS = {
	[PLANS.BP]: "Subscriptions.Plans.Descriptions.BP",
	[PLANS.BP_ANNUAL]: "Subscriptions.Plans.Descriptions.BP",
	[PLANS.BP_EMPLOYEE]: "Subscriptions.Plans.Descriptions.BP",
	[PLANS.BP_PARTNER]: "Subscriptions.Plans.Descriptions.BP",

	[PLANS.BP_LINK]: "Subscriptions.Plans.Descriptions.BP_LINK",
	[PLANS.BP_LINK_ANNUAL]: "Subscriptions.Plans.Descriptions.BP_LINK",
	[PLANS.BP_LINK_EMPLOYEE]: "Subscriptions.Plans.Descriptions.BP_LINK",
	[PLANS.BP_LINK_PARTNER]: "Subscriptions.Plans.Descriptions.BP_LINK",

	[PLANS.BP_PRO]: "Subscriptions.Plans.Descriptions.BP_PRO",
	[PLANS.BP_PRO_ANNUAL]: "Subscriptions.Plans.Descriptions.BP_PRO",
	[PLANS.BP_PRO_EMPLOYEE]: "Subscriptions.Plans.Descriptions.BP_PRO",
	[PLANS.BP_PRO_PARTNER]: "Subscriptions.Plans.Descriptions.BP_PRO",
	[PLANS.BP_PRO_AMBASSADOR]: "Subscriptions.Plans.Descriptions.BP_PRO",

	[PLANS.BP_PRO_PLUS]: "Subscriptions.Plans.Descriptions.BP_PRO_PLUS",
	[PLANS.BP_PRO_PLUS_EMPLOYEE]: "Subscriptions.Plans.Descriptions.BP_PRO_PLUS",
	[PLANS.BP_PRO_PLUS_PARTNER]: "Subscriptions.Plans.Descriptions.BP_PRO_PLUS",
	[PLANS.BP_PRO_PLUS_AMBASSADOR]: "Subscriptions.Plans.Descriptions.BP_PRO_PLUS",
};

const COMMON_FEATURES = [
	"Subscriptions.Plans.CommonFeatures.ReDownload",
	"Subscriptions.Plans.CommonFeatures.FullTrackPlayback",
];

const ADVANCED_PRO_FEATURES = [
	"Subscriptions.Plans.CommonFeatures.DJAcademy",
	"Subscriptions.Plans.CommonFeatures.Integrations",
];

export const FEATURE_BULLETS: { [key: string]: string[] } = {
	["Subscriptions.Plans.CommonFeatures.FullTrackPlayback"]: [
		"Subscriptions.Plans.CommonFeatures.Website",
		"Subscriptions.Plans.CommonFeatures.MobileApp",
		"Subscriptions.Plans.CommonFeatures.DjWebApp",
	],
};

const BASE_PLAN_FEATURES = {
	[PLANS.BP]: [
		...COMMON_FEATURES,
	],
	[PLANS.BP_ANNUAL]: [
		...COMMON_FEATURES,
	],
	[PLANS.BP_LINK]: [
		...COMMON_FEATURES,
	],
	[PLANS.BP_LINK_ANNUAL]: [
		...COMMON_FEATURES,
	],
	[PLANS.BP_PRO]: [
		...COMMON_FEATURES,
		...ADVANCED_PRO_FEATURES,
	],
	[PLANS.BP_PRO_ANNUAL]: [
		...COMMON_FEATURES,
		...ADVANCED_PRO_FEATURES,
	],
	[PLANS.BP_PRO_PLUS]: [
		...COMMON_FEATURES,
		...ADVANCED_PRO_FEATURES,
	],
};

export const PLAN_FEATURES = {
	[PLANS.BP]: BASE_PLAN_FEATURES[PLANS.BP],
	[PLANS.BP_ANNUAL]: BASE_PLAN_FEATURES[PLANS.BP],
	[PLANS.BP_EMPLOYEE]: BASE_PLAN_FEATURES[PLANS.BP],
	[PLANS.BP_PARTNER]: BASE_PLAN_FEATURES[PLANS.BP],

	[PLANS.BP_LINK]: BASE_PLAN_FEATURES[PLANS.BP_LINK],
	[PLANS.BP_LINK_ANNUAL]: BASE_PLAN_FEATURES[PLANS.BP_LINK],
	[PLANS.BP_LINK_EMPLOYEE]: BASE_PLAN_FEATURES[PLANS.BP_LINK],
	[PLANS.BP_LINK_PARTNER]: BASE_PLAN_FEATURES[PLANS.BP_LINK],

	[PLANS.BP_PRO]: BASE_PLAN_FEATURES[PLANS.BP_PRO],
	[PLANS.BP_PRO_ANNUAL]: BASE_PLAN_FEATURES[PLANS.BP_PRO],
	[PLANS.BP_PRO_EMPLOYEE]: BASE_PLAN_FEATURES[PLANS.BP_PRO],
	[PLANS.BP_PRO_PARTNER]: BASE_PLAN_FEATURES[PLANS.BP_PRO],
	[PLANS.BP_PRO_AMBASSADOR]: BASE_PLAN_FEATURES[PLANS.BP_PRO],

	[PLANS.BP_PRO_PLUS]: BASE_PLAN_FEATURES[PLANS.BP_PRO_PLUS],
	[PLANS.BP_PRO_PLUS_EMPLOYEE]: BASE_PLAN_FEATURES[PLANS.BP_PRO_PLUS],
	[PLANS.BP_PRO_PLUS_PARTNER]: BASE_PLAN_FEATURES[PLANS.BP_PRO_PLUS],
	[PLANS.BP_PRO_PLUS_AMBASSADOR]: BASE_PLAN_FEATURES[PLANS.BP_PRO_PLUS],
};

const BASE_PLAN_HIGHLIGHTS = {
	[PLANS.BP]: [],
	[PLANS.BP_LINK]: [
		"Subscriptions.Plans.CommonFeatures.DJAcademy",
		"Subscriptions.Plans.CommonFeatures.Integrations",
	],
	[PLANS.BP_LINK_ANNUAL]: [
		"Subscriptions.Plans.CommonFeatures.DJAcademy",
		"Subscriptions.Plans.CommonFeatures.Integrations",
	],
	[PLANS.BP_PRO]: [
		"Subscriptions.Plans.CommonFeatures.HighQualityLosslessAudio",
		"Subscriptions.Plans.CommonFeatures.OfflinePlayback",
	],
	[PLANS.BP_PRO_ANNUAL]: [
		"Subscriptions.Plans.CommonFeatures.HighQualityLosslessAudio",
		"Subscriptions.Plans.CommonFeatures.OfflinePlayback",
	],
	[PLANS.BP_PRO_PLUS]: [
		"Subscriptions.Plans.CommonFeatures.HighQualityLosslessAudio",
		"Subscriptions.Plans.CommonFeatures.OfflinePlayback",
	],
};

export const PLAN_HIGHLIGHTS = {
	[PLANS.BP]: BASE_PLAN_HIGHLIGHTS[PLANS.BP],
	[PLANS.BP_ANNUAL]: BASE_PLAN_HIGHLIGHTS[PLANS.BP],
	[PLANS.BP_EMPLOYEE]: BASE_PLAN_HIGHLIGHTS[PLANS.BP],
	[PLANS.BP_PARTNER]: BASE_PLAN_HIGHLIGHTS[PLANS.BP],

	[PLANS.BP_LINK]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_LINK],
	[PLANS.BP_LINK_ANNUAL]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_LINK],
	[PLANS.BP_LINK_EMPLOYEE]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_LINK],
	[PLANS.BP_LINK_PARTNER]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_LINK],

	[PLANS.BP_PRO]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_PRO],
	[PLANS.BP_PRO_ANNUAL]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_PRO],
	[PLANS.BP_PRO_EMPLOYEE]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_PRO],
	[PLANS.BP_PRO_PARTNER]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_PRO],
	[PLANS.BP_PRO_AMBASSADOR]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_PRO],

	[PLANS.BP_PRO_PLUS]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_PRO_PLUS],
	[PLANS.BP_PRO_PLUS_EMPLOYEE]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_PRO_PLUS],
	[PLANS.BP_PRO_PLUS_PARTNER]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_PRO_PLUS],
	[PLANS.BP_PRO_PLUS_AMBASSADOR]: BASE_PLAN_HIGHLIGHTS[PLANS.BP_PRO_PLUS],
};

export const FEATURE_FOOTNOTES: { [key: string]: string } = {
	"Subscriptions.Plans.CommonFeatures.ReDownload": "Subscriptions.Plans.Footnotes.NoFreeDownloads",
	"Subscriptions.Plans.CommonFeatures.FullTrackPlayback": "Subscriptions.Plans.Footnotes.StreamingQuality",
	"Subscriptions.Plans.CommonFeatures.DJAcademy": "Subscriptions.Plans.Footnotes.FreeAcademyAccess",
	"Subscriptions.Plans.CommonFeatures.Integrations": "Subscriptions.Plans.Footnotes.AvailableIntegrations",
	"Subscriptions.Plans.CommonFeatures.HighQualityLosslessAudio": "Subscriptions.Plans.Footnotes.LosslessAudioUnsupported",
	"Subscriptions.Plans.CommonFeatures.OfflinePlayback": "Subscriptions.Plans.Footnotes.OfflineLibraryUnsupported",
};

export const FEATURES_NEW: string[] = [
	"Subscriptions.Plans.CommonFeatures.HighQualityLosslessAudio",
];

export const HIGHLIGHT_LINKS: { [key: string]: string } = {
	"Subscriptions.Plans.CommonFeatures.DJAcademy": "https://petetong-djacademy.com/beatport-lite-version/",
};

export const FEATURE_CAPTIONS: { [key: string]: string[] } = {};

export const FOOTNOTE_LINKS: { [key: string]: { [linkType: string]: string } } = {
	"Subscriptions.Plans.Footnotes.StreamingQuality": {
		learnMore: "https://support.beatport.com/hc/en-us/articles/27893825412500-What-is-the-audio-quality-like-with-Beatport-Streaming",
	},
};

export const CHECKOUT_VARIANTS = {
	TRIAL: "30 day Trial",
	NEW_SUBSCRIPTION: "New subscription",
	SUBSCRIPTION_UPDATE: "Subscription update",
	SUBSCRIPTION_NO_CHANGE: "Current plan",
};

export const PURCHASE_CATEGORIES = {
	FREE_TRIAL: "FREE Trial - Subscription",
	SUBSCRIPTION: "Subscription",
};

export const MAX_FREE_DOWNLOADS = 20;

export const YEARLY_DISCOUNT_PERCENTAGE = 17;

export enum RECURLY_STATUS {
	ACTIVE = "active",
	CANCELED = "canceled",
	EXPIRED = "expired",
	IN_TRIAL = "in_trial",
	DOWNGRADE = "downgrade to ",
};
