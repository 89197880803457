import styled from "styled-components";

export const ButtonStyled = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px 7px;
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-size: ${(props) => props.theme.fontSizes[4].size};
  line-height: ${(props) => props.theme.fontSizes[4].lineHeight};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.colors.neutrals.primary[900]};

  /* primary buttons */
  &.primary {
    background: ${(props) => props.theme.colors.green[300].accent};
    &:hover {
      background: ${(props) => props.theme.colors.green[300].stroke};
    }

    &.primary.disabled {
      pointer-events: none;
      color: ${(props) => props.theme.colors.neutrals.primary[600]};
      background: ${(props) =>
			props.theme.colors.neutrals.secondary.gray.divider2};
    }
  }

  /* outline buttons */
  &.outline {
    border: 1px solid;
    border-image-source: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
      ),
      linear-gradient(
        0deg,
        ${(props) => props.theme.colors.green[300].accent},
        ${(props) => props.theme.colors.green[300].accent}
      );

    color: ${(props) => props.theme.colors.green[300].accent};
    &:hover {
      border: 1px solid;
      color: ${(props) => props.theme.colors.neutrals.primary[900]};
      background: ${(props) => props.theme.colors.green[300].accent};
    }

    &.outline.disabled {
      background: transparent;
      color: ${(props) => props.theme.colors.green[300].accent};
      opacity: 50%;
    }
  }

  /* outline buttons */
  &.outline-secondary {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.neutrals.primary.graytext};
    color: ${(props) => props.theme.colors.neutrals.primary.white};

    &:hover {
      background: ${(props) => props.theme.colors.neutrals.primary.graytext};
    }

    &.outline.disabled {
      background: transparent;
      color: ${(props) => props.theme.colors.neutrals.primary.white};
      opacity: 50%;
    }
  }

  /* tertiary buttons */
  &.tertiary {
    color: ${(props) => props.theme.colors.neutrals.primary.white};
    background: ${(props) => props.theme.colors.neutrals.primary.graytext};
    &:hover {
      background: ${(props) => props.theme.colors.gray["9"]};
    }
    &.tertiary.disabled {
      background: ${(props) => props.theme.colors.neutrals.primary.graytext};
      opacity: 0.5;
    }
  }

  /* secondary buttons */
  &.secondary {
    color: ${(props) => props.theme.colors.neutrals.primary.white};

    background: ${(props) => props.theme.colors.gray["9"]};
    &:hover {
      background: ${(props) => props.theme.colors.neutrals.primary.graytext};
    }
    &.disabled {
      background: ${(props) => props.theme.colors.neutrals.primary.graytext};
      opacity: 0.5;
    }
  }

  /* danger buttons */
  &.danger {
    color: ${(props) => props.theme.colors.neutrals.primary.white};
    background: ${(props) => props.theme.colors.semantic.danger};
    &:hover {
      background: ${(props) => props.theme.colors.semantic.danger};
    }
    &.disabled {
      background: ${(props) => props.theme.colors.neutrals.primary.graytext};
      opacity: 0.5;
    }
  }

  &.link {
    color: ${(props) => props.theme.colors.neutrals.primary.white};
  }

  &.primary-link {
    color: ${(props) => props.theme.colors.green[300].accent};
    &.disabled {
      cursor: default;
    }
  }

  &.w-100 {
    width: 100%;
  }
  &.w-75 {
    width: 75%;
  }
  &.w-50 {
    width: 50%;
  }
  &.w-25 {
    width: 25%;
  }

  &.squared {
    padding: 5px;
  }
`;
