import styled, { css } from "styled-components";

/*
  These styles are shared among ReleasesList, TracksList, & TrackListItems.

  If you need to modify a shared style, ensure you want to make the same modification
  to all the shared components. Otherwise, override the style in the specific style
  file for the component.
*/

const TrimmedArtwork = css`
  object-fit: cover;
  object-position: left center;
  width: 50px;
`;

export const Artwork = styled.div<{ $withTrim?: boolean }>`
  height: 80px;
  width: 80px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    left: 0;
    height: 80px;
    width: 80px;
    ${({ $withTrim }) => $withTrim && TrimmedArtwork}
  }
`;

export const CartMeta = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
`;

export const Exclusive = styled.span`
  left: -33px;
  top: 33px;
  height: 14px;
  padding: 0 2px;
  font-size: 7px;
  background: ${(props) => props.theme.colors.green[300].accent};
  width: 100%;
  color: #000;
  position: absolute;
  text-align: center;
  box-sizing: border-box;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  transform: rotate(-90deg);
`;

export const ItemControls = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 80px;
  width: 40px;
  overflow: hidden;
  transition: width 0.2s ease-in-out;
  color: ${(props) => props.theme.colors.neutrals.primary.white};
`;

export const MetaRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a:hover {
    text-decoration: underline;
  }
`;

export const MoreButton = styled.a`
  cursor: pointer;
`;

export const Spacer = styled.div`
  width: 1.5rem;
`;

export const ItemName = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.neutrals.primary.white};

  span {
    font-weight: 400;
  }
`;

export const ItemNumber = styled.span`
  display: flex;
  justify-content: center;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
  width: 2.25rem;
  min-width: 2.25rem;
`;

export const ItemMeta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 7.813rem);
  height: 100%;
  position: absolute;
  left: 5rem;
  overflow-x: clip;
  padding-right: 1rem;
`;

export const ItemActions = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  left: 100%;
  transition: left 0.2s ease-in-out;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  color: ${(props) => props.theme.colors.neutrals.primary.white};
  overflow-x: hidden;

  @supports (overflow-x: clip) {
    overflow-x: clip;
  }

  div.actions {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 0.5rem;
    padding-left: 2.25rem;
    @media ${(props) => props.theme.device.xl} {
      justify-content: flex-start;
    }
    span.unavailable {
      padding: 1.25rem;
    }
  }

  div.cart-list-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.cart-actions {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    padding-left: 0px;
    flex-wrap: wrap;
  }
`;

export const ItemButtons = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 100%;
  width: 2.5rem;
  border-left: 0.063rem solid ${(props) => props.theme.colors.neutrals.primary[800]};

  &.borderless {
    border-left: none;
  }

  .tombstoned {
    opacity: 0.15;
  }

  .selectable {
    &:hover {
      opacity: 1;
    }
  }
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5rem;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.neutrals.primary.graytext};

  &.current {
    background: ${(props) => props.theme.colors.neutrals.secondary.gray.active};
    ${ItemActions} {
      background: ${(props) =>
			props.theme.colors.neutrals.secondary.gray.active};
    }
  }

  &.tombstoned {
    background: ${(props) => props.theme.colors.neutrals.primary[700]};
    a {
      pointer-events: none;
    }
    &:hover {
      background: ${(props) => props.theme.colors.neutrals.primary[700]};
    }
  }

  ${ItemControls} {
    overflow: visible;
  }

  &.actions ${ItemActions} {
    left: 0;
  }

  .play-hover {
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 2.25rem;
    min-width: 2.25rem;
  }

  &.no-artwork {
    ${ItemMeta} {
      left: 0;
      width: calc(100% - 3.125rem);
    }
  }

  &.partial-artwork {
    ${ItemMeta} {
      left: 3rem;
      width: calc(100% - 3.125rem);
    }
  }

  &.force-hover {
    span.play {
      display: none;
    }

    ${ItemControls} {
      display: none;
      width: calc(100% - 3.125rem);
    }

    &:hover {
      .play-hover {
        display: flex;
      }
      ${ItemNumber} {
        display: none;
      }

      ${ItemActions} {
        justify-content: flex-start;
        left: 8.625rem;
        @media ${(props) => props.theme.device.xxl} {
          left: 10.5rem;
        }
      }
    }
    
    &.partial-artwork:hover {
      ${ItemActions} {
        left: 5.5rem;
        @media ${(props) => props.theme.device.xxl} {
          left: 7.5rem;
        }
      }
    }
  }

  &.force-hover.no-artwork {
    ${ItemMeta} {
      left: 0;
      width: 100%;
    }
  }

  &.cart-list {
    ${ItemActions} {
      gap: 0px;
      div.actions {
        justify-content: center;
      }

      div.cart-actions {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  svg.album-cover {
    display: none;
    position: absolute;
    margin-left: 1.25rem;
  }

  &:hover {
    background: ${(props) => props.theme.colors.neutrals.secondary.gray.active};
    svg.album-cover {
      display: inline;
    }

    ${ItemActions} {
      background: ${(props) =>
			props.theme.colors.neutrals.secondary.gray.active};
    }
  }
`;

export const Wrapper = styled.div`
  &.row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 0.5rem;
    padding: 1rem 0;
    width: 100%;

    &.panel-list {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }

    @media ${(props) => props.theme.device.lg} {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
  }

  &.column {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.25rem;
    padding: 1rem 0;
  }

  .droppable {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const ListHeading = styled.div`
  height: 3rem;
  border-top: 0.063rem solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.divider1};
  padding: 0.625rem 3.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  margin-bottom: 0.5rem;
`;

export const AddTrackControls = styled.div`
  padding: 0 0.5rem;
`;

export const ItemImageWrapper = styled.div`
  width: 8rem;
`;

export const ItemImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemDataWrapper = styled.div`
  width: calc(100% - 8rem);
  display: flex;
  justify-content: space-between;
`;

export const ItemDataContainer = styled.div`
  width: 70%;
`;

export const ItemControlsContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  width: 9.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.5rem;
  }
`;
