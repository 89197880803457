export enum InternalUrls {
	ABOUT = "https://about.beatport.com/",
	CAREERS = "https://about.beatport.com/careers/",
	CONTACT_US = "https://support.beatport.com/hc/en-us/articles/4412288252308",
	COPYRIGHT_INFRINGEMENT = "https://research.beatport.com/jfe/form/SV_9FYk8tRdkWAmAJg",
	CUSTOMER_SUPPORT = "https://support.beatport.com/hc/en-us",
	LOGOS = "https://support.beatport.com/hc/en-us/articles/4412316336404-Beatport-Logos-and-Images",
	PRIVACY_POLICY = "https://support.beatport.com/hc/en-us/articles/4412316093588",
	TERMS_AND_CONDITIONS = "https://support.beatport.com/hc/en-us/articles/4414997837716",
}

export enum NetworkUrls {
	AMPSUITE = "https://www.ampsuite.com/",
	BEATPORTAL = "https://www.beatportal.com/",
	BEATPORT_DJ = "https://dj.beatport.com/home",
	BEATPORT_HYPE = "https://labels.beatport.com/hype",
	BEATPORT_LABEL = "https://labels.beatport.com/",
	BEATPORT_NEXT = "https://www.beatportal.com/beatportnext",
	BEATPORT_STREAMING = "https://stream.beatport.com/",
	LABEL_RADAR = "https://www.labelradar.com/?utm_medium=referral&utm_source=beatport&utm_campaign=evergreen&utm_content=footer",
	LOOPCLOUD = "https://www.loopcloud.com",
	PLUGIN_BOUTIQUE = "https://www.pluginboutique.com/",
}

export enum SocialMediaUrls {
	DISCORD = "https://discord.gg/ezBmCaCknd",
	FACEBOOK = "https://www.facebook.com/beatport",
	INSTAGRAM = "https://www.instagram.com/beatport",
	TIKTOK = "https://www.tiktok.com/@beatport",
	TWITTER = "https://www.twitter.com/beatport",
	YOUTUBE = "https://www.youtube.com/c/beatport",
}
