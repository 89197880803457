import { ItemMeta } from "@components/lists/Lists.shared.style";
import styled from "styled-components";

export const Wrapper = styled.div`
  height: calc(100vh - 215px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    // hide scrollbar webkit
    display: none;
  }
  -ms-overflow-style: none; // hide scrollbar IE
  scrollbar-width: none; // hide scrollbar firefox

  max-height: 680px;
  min-width: 330px;
  max-width: 772px;

  @media ${(props) => props.theme.device.lg} {
    width: 772px;
    overflow-y: scroll;
  }

  ${ItemMeta} {
    width: calc(100% - 135px);
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.313rem 0 1.25rem 0;
`;

export const Tab = styled.div`
  display: flex;
  cursor: pointer;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${(props) => props.theme.fontSizes[6]};
  padding-bottom: 4px;
  &.selected {
    border-bottom: 2px solid ${(props) => props.theme.colors.green[300].accent};
  }
`;

export const TabContent = styled.div`
  display: flex;
  width: 100%;
`;
