/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@components/Button";
import { Box } from "@components/core/Box";
import { CheckBox, Select, TextInput } from "@components/core/Form";
import { Modal } from "@components/core/Modal";
import DateFilter from "@components/filters/DateFilter";
import {
	Field,
	Filter,
	FilterForm,
	FilterFormBody,
	FilterFormFooter,
	Filters,
	MobileWrapper,
	Reset,
	Sorter,
	Wrapper,
} from "@components/filters/FacetFilter/FacetFilter.style";
import BpmFilter from "@components/filters/common/BpmFilter";
import { Tooltip } from "@components/interaction";
import { DATE_FILTER_FORMAT } from "@lib/constants";
import {
	ACTIVE_FILTER,
	ACTIVE_FORM_TYPES,
	BPM_LIMITS,
	DEFAULT_TEXT_FILTERS,
	EMPTY_BPM_FILTER,
	EMPTY_DATE_FILTER,
	EMPTY_TEXT_FILTER,
} from "@lib/constants/filters";
import {
	SEARCH_MAPPINGS,
	SORT_MAP,
	TRACK_SORT_OPTIONS,
} from "@lib/constants/search";
import { cls } from "@lib/css";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { DateFilterProps } from "@models/facets";
import { device } from "@styles/theme";
import { format } from "@lib/utils/dateFnsWrapper";
import { Trans, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import "react-day-picker/dist/style.css";

interface Field {
	id: number;
	name: string;
	count?: number;
	checked?: boolean;
	disabled?: boolean;
}

interface TextFilter {
	className: string;
	inputName: string;
	stateName: string;
	inputLabel: string;
}
interface Facets {
	genre?: Field[];
	sub_genre?: Field[];
	key?: Field[];
	artists?: Field[];
	label?: Field[];
	type?: Field[];
	artist_name?: Field[];
	label_name?: Field[];
	track_name?: Field[];
}

interface Props {
	showReleaseDateFilter?: boolean;
	showPurchaseDateFilter?: boolean;
	showPublishDateFilter?: boolean;
	disableSubgenres?: boolean;
	showBPMFilter?: boolean;
	showResetAll?: boolean;
	facets?: Facets;
	hide?: string[];
	showArtistTextFilter?: boolean;
	showTrackTextFilter?: boolean;
	showLabelTextFilter?: boolean;
	onChange: (filters: Record<string, string>) => void;
	onReset: (name?: string) => void;
	hideOrderBy?: boolean;
	modalFilterApplied?: boolean;
	modal?: boolean;
}

const SearchFilter: React.FC<Props> = ({
	showPurchaseDateFilter = false,
	showReleaseDateFilter = false,
	showPublishDateFilter = false,
	disableSubgenres = false,
	showBPMFilter,
	facets,
	hide,
	showTrackTextFilter,
	showArtistTextFilter,
	showLabelTextFilter,
	showResetAll,
	onChange,
	onReset,
	hideOrderBy,
	modalFilterApplied,
	modal,
}) => {
	const { t } = useTranslation("translation");
	const isDesktop = useMediaQuery({ query: device.lg });
	const [state, setState] = useState<Record<string, number[]>>({});
	const [activeFilterKey, setActiveFilterKey] = useState("");
	const [bpmFilter, setBpmFilter] = useState(EMPTY_BPM_FILTER);

	const [dateFilter, setDateFilter] =
		useState<Record<string, DateFilterProps>>(EMPTY_DATE_FILTER);

	const [textFilter, setTextFilter] =
		useState<Record<string, string>>(EMPTY_TEXT_FILTER);

	const router = useRouter();
	const filterApplied =
		modalFilterApplied ||
		(router !== null ? router.asPath?.includes("&") : undefined);

	const updateGroupItem = (name: string, value: number) => {
		const groupState = state[name] || [];
		if (groupState.includes(value)) {
			// remove item
			groupState.splice(groupState.indexOf(value), 1);
		} else {
			groupState.push(value);
		}

		state[name] = groupState;
		setState({ ...state });
	};

	const apply = () => {
		const data = {} as Record<string, string>;
		Object.keys(state).forEach((key) => {
			if (SEARCH_MAPPINGS[key]) {
				data[SEARCH_MAPPINGS[key]] = state[key].join(",");
			}
		});
		onChange(data);
		setActiveFilterKey("");
	};

	const applySort = (sort: string) => {
		const parts = sort.split("-");
		const data = {} as Record<string, string>;
		data["order_by"] = `${parts[1] === "asc" ? "" : "-"}${SORT_MAP[parts[0]]}`;

		Object.keys(state).forEach((key) => {
			if (SEARCH_MAPPINGS[key]) {
				data[SEARCH_MAPPINGS[key]] = state[key].join(",");
			}
		});

		onChange(data);
	};

	const applyDateFilter = (filterValue: DateFilterProps) => {
		const data = {} as Record<string, string>;
		const min = filterValue.min ?
				format(filterValue.min, DATE_FILTER_FORMAT) :
				format(new Date(), DATE_FILTER_FORMAT);
		const max = filterValue.max ?
				format(filterValue.max, DATE_FILTER_FORMAT) :
				format(new Date(), DATE_FILTER_FORMAT);

		data[filterValue.inputName] = `${min}:${max}`;
		onChange(data);
		setActiveFilterKey("");
		renderActiveForm("");
	};
	const applyBpm = () => {
		const data = {} as Record<string, string>;
		const bpmMin =
			bpmFilter.min < BPM_LIMITS.min || isNaN(bpmFilter.min) ?
				BPM_LIMITS.min :
				bpmFilter.min;
		const bpmMax =
			bpmFilter.max < BPM_LIMITS.min || isNaN(bpmFilter.max) ?
				BPM_LIMITS.max :
				bpmFilter.max;
		data["bpm"] = `${bpmMin}:${bpmMax}`;
		onChange(data);
		setActiveFilterKey("");
	};

	const applyTextFilter = (filterName: string, filterValue: string) => {
		const data = {} as Record<string, string>;
		data[filterName] = filterValue;
		onChange(data);
		setActiveFilterKey("");
	};

	const resetGroup = (name: string) => {
		state[name] = [];

		setState({ ...state });

		apply();
	};

	useEffect(() => {
		apply();
	}, [state]);

	const resetAll = () => {
		setState({});
		onReset();
		setActiveFilterKey("");
		setDateFilter(EMPTY_DATE_FILTER);
		setBpmFilter(EMPTY_BPM_FILTER);
		setTextFilter(EMPTY_TEXT_FILTER);
	};

	const isHidden = (name: string) => (hide && hide.includes(name)) || false;

	useEffect(() => {
		// set checked genre checkboxes on mount
		const names = ["genre", "key", "sub_genre"];
		names.map((name: string) => {
			const groupState = state[name] || [];
			if (facets !== undefined) {
				for (const [key, value] of Object.entries(facets)) {
					if (key === name) {
						const genre = value;
						genre?.map(
							(item: {
								id: number;
								name: string;
								checked?: boolean;
								count?: number;
							}) => {
								if (item.checked) {
									if (!state[name]?.includes(item.id)) {
										groupState.push(item.id);
									}
								}
							},
						);
					}
				}
			}

			state[name] = groupState;
		});
		setState({ ...state });
	}, []);

	const renderFilters = (group: string, fields: Field[]) => {
		const groupState = state[group] || [];
		return (
			<FilterForm
				key={`group-${group}`}
				className={cls(`group-${group}`, modal ? "modal" : "")}
			>
				<FilterFormBody>
					<ul>
						{fields.map((item) => (
							<Field key={`group-${group}-${item.id}`}>
								<CheckBox
									label={`${item.name}`}
									id={item.id.toString()}
									name={item.name}
									value={item.id}
									checked={groupState.includes(item.id)}
									updateGroupItem={() => updateGroupItem(group, item.id)}
									onChange={() => {
										updateGroupItem(group, item.id);
									}}
								/>
							</Field>
						))}
					</ul>
				</FilterFormBody>
				<FilterFormFooter>
					<Button type="link" onClick={() => resetGroup(group)}>
						{t("Reset")}
					</Button>
					<Button type="primary" onClick={() => apply()}>
						{t("Apply")}
					</Button>
				</FilterFormFooter>
			</FilterForm>
		);
	};

	const renderDateFilter = (
		{ filterKey, filterDetails, isMobileFilter = false }: { filterKey: string; filterDetails: DateFilterProps; isMobileFilter?: boolean },
	) => {
		const className = cls(
			filterDetails.min || filterDetails.max ? ACTIVE_FILTER : "",
			isMobileFilter ? "mobile" : "",
		);

		return (
			<Filter
				className={className}
				onClick={() => {
					if (!isDesktop) {
						setActiveFilterKey(filterDetails.inputName);
					}
				}}
			>
				{!isMobileFilter && (
					<div className="button-wrapper">
						<span><Trans>{filterDetails.inputLabel}</Trans></span>
						<i className="arrow" />
					</div>
				)}

				{(isMobileFilter || isDesktop) && (
					<DateFilter
						dateFilter={filterDetails}
						setDateFilter={(newDateValue: DateFilterProps) => {
							dateFilter[filterKey as string] = newDateValue;
							setDateFilter({ ...dateFilter });
						}}
						applyDateFilter={applyDateFilter}
						resetGroup={() => resetGroup(filterDetails.inputName)}
					/>
				)}
			</Filter>
		);
	};

	const renderTextFilterForm = (filterDetails: TextFilter) => {
		const className = filterDetails.className;
		const inputName = filterDetails.inputName;
		const stateName = filterDetails.stateName;
		return (
			<FilterForm className={cls(className, modal ? "modal" : "")}>
				<FilterFormBody>
					<Box p="0" direction="row">
						<Box p="4" width="90">
							<TextInput
								id={inputName}
								name={inputName}
								type="text"
								placeholder="Filter by..."
								value={textFilter[stateName]}
								onChange={(e: any) => {
									textFilter[stateName as string] = e.target.value;
									setTextFilter({ ...textFilter });
								}}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										e.preventDefault(); // Ensure it is only this code that runs
										applyTextFilter(inputName, textFilter[stateName]);
									}
								}}
							/>
						</Box>
					</Box>
				</FilterFormBody>
				<FilterFormFooter>
					<Button
						type="link"
						onClick={() => {
							textFilter[stateName] = "";
							setTextFilter({ ...textFilter });
							applyTextFilter(inputName, "");
						}}
					>
						{t("Reset")}
					</Button>
					<Button
						type="primary"
						onClick={() => applyTextFilter(inputName, textFilter[stateName])}
					>
						{t("Apply")}
					</Button>
				</FilterFormFooter>
			</FilterForm>
		);
	};

	const renderBPMFilter = () => (
		<FilterForm className="group-bpm">
			<FilterFormBody>
				<BpmFilter.Inputs bpmValues={bpmFilter} onSetBpm={setBpmFilter} />
			</FilterFormBody>
			<FilterFormFooter>
				<BpmFilter.Buttons
					bpmValues={bpmFilter}
					onReset={(data) => {
						resetGroup("bpm");
						setBpmFilter(data);
					}}
					onApply={applyBpm}
				/>
			</FilterFormFooter>
		</FilterForm>
	);

	const renderActiveForm = (key: string) => {
		switch (key) {
			case ACTIVE_FORM_TYPES.RELEASE_DATE:
				return renderDateFilter({ filterKey: "releaseDate", filterDetails: dateFilter.releaseDate, isMobileFilter: true });
			case ACTIVE_FORM_TYPES.PURCHASE_DATE:
				return renderDateFilter({ filterKey: "purchaseDate", filterDetails: dateFilter.purchaseDate, isMobileFilter: true });
			case ACTIVE_FORM_TYPES.PUBLISH_DATE:
				return renderDateFilter({ filterKey: "publishDate", filterDetails: dateFilter.publishDate, isMobileFilter: true });
			case ACTIVE_FORM_TYPES.BPM:
				return renderBPMFilter();
			case ACTIVE_FORM_TYPES.ARTIST_NAME:
				return renderTextFilterForm(DEFAULT_TEXT_FILTERS.artist);
			case ACTIVE_FORM_TYPES.LABEL_NAME:
				return renderTextFilterForm(DEFAULT_TEXT_FILTERS.label);
			case ACTIVE_FORM_TYPES.TRACK_NAME:
				return renderTextFilterForm(DEFAULT_TEXT_FILTERS.track);
		}

		if (facets && key !== "") {
			const data = (facets as any)[key] as Field[];
			return renderFilters(key, data);
		}
	};

	const renderTextFilter = (filterDetails: TextFilter) => {
		return (
			<Filter
				className={
					textFilter[filterDetails.stateName].length ? ACTIVE_FILTER : undefined
				}
				onClick={() => {
					if (!isDesktop) {
						setActiveFilterKey(filterDetails.inputName);
					}
				}}
			>
				<span><Trans>{filterDetails.inputLabel}</Trans></span>
				<i className="arrow" />
				{isDesktop && renderTextFilterForm(filterDetails)}
			</Filter>
		);
	};

	const renderData = (facets: Facets) =>
		Object.keys(facets)
			.filter((key) => {
				const data = (facets as any)[key] as Field[];
				return (
					!isHidden(key) && data && (data.length > 0 || key === "sub_genre")
				);
			})
			.map((key) => {
				const groupName = key.charAt(0).toUpperCase() + key.slice(1);
				const data = (facets as any)[key] as Field[];
				const disabled = key === "sub_genre" && disableSubgenres;

				// Class names
				const activeClass =
					state[key] && state[key].length > 0 ? ACTIVE_FILTER : "";
				const disabledClass = disabled ? "disabled" : "";
				const classNames = `${activeClass} ${disabledClass}`;

				const tooltipText = key === "sub_genre" ? "Select a genre" : "";

				const renderFilter = () => (
					<Filter
						className={classNames}
						id={`facet-${key}`}
						onClick={() => {
							if (!isDesktop && !disabled) {
								setActiveFilterKey(key);
							}
						}}
					>
						<span>{groupName.replace("_", "-")}</span>
						<i className="arrow" />
						{isDesktop && !disabled && renderFilters(key, data)}
					</Filter>
				);

				return disabled ?
						(
							<Tooltip key={`facet-${key}`} text={tooltipText}>
								{renderFilter()}
							</Tooltip>
						) :
						(
							<Fragment key={`facet-${key}`}>{renderFilter()}</Fragment>
						);
			});

	return (
		<Wrapper hideOrderBy>
			<Filters hideOrderBy className="search-filter">
				{showTrackTextFilter && renderTextFilter(DEFAULT_TEXT_FILTERS.track)}
				{showReleaseDateFilter &&
					renderDateFilter({ filterKey: "releaseDate", filterDetails: dateFilter.releaseDate, isMobileFilter: false })}
				{showPublishDateFilter &&
					renderDateFilter({ filterKey: "publishDate", filterDetails: dateFilter.publishDate, isMobileFilter: false })}
				{showPurchaseDateFilter &&
					renderDateFilter({ filterKey: "purchaseDate", filterDetails: dateFilter.purchaseDate, isMobileFilter: false })}

				{showBPMFilter && (
					<Filter
						className={
							bpmFilter.min > 0 || bpmFilter.max > 0 ? ACTIVE_FILTER : undefined
						}
						onClick={() => {
							if (!isDesktop) {
								setActiveFilterKey("bpm");
							}
						}}
					>
						<span>BPM</span>
						<i className="arrow" />
						{isDesktop && renderBPMFilter()}
					</Filter>
				)}
				{facets && renderData(facets)}
				{showArtistTextFilter && renderTextFilter(DEFAULT_TEXT_FILTERS.artist)}
				{showLabelTextFilter && renderTextFilter(DEFAULT_TEXT_FILTERS.label)}
				<Reset
					className={filterApplied || showResetAll ? "" : "disabled"}
					onClick={() => {
						resetAll();
					}}
				>
					{t("ResetAll")}
				</Reset>
				<Modal
					show={activeFilterKey !== ""}
					onClose={() => {
						setActiveFilterKey("");
					}}
				>
					<MobileWrapper>{renderActiveForm(activeFilterKey)}</MobileWrapper>
				</Modal>
			</Filters>
			{!hideOrderBy && (
				<Sorter>
					<span>Sort By:</span>
					<Select
						id="sort"
						name="sort"
						options={TRACK_SORT_OPTIONS}
						onChange={(e) => {
							applySort(e.target.value);
						}}
						value={
							TRACK_SORT_OPTIONS.find(
								(item) => router?.query?.order_by === item.queryStr,
							)?.value
						}
					/>
				</Sorter>
			)}
		</Wrapper>
	);
};

export default SearchFilter;
