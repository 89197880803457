import { convertToSlug } from "@lib/constants";
import { Artist } from "@models/artist";
import { Chart, Genre } from "@models/Chart";
import { Label } from "@models/label";
import { Release, Artist as ReleaseArtist } from "@models/release";
import { SearchArtist } from "@models/search-artist";
import { SearchChart, Genre as SearchChartGenre } from "@models/search-chart";
import { SearchLabel } from "@models/search-label";
import {
	SearchRelease,
	Artist as SearchReleaseArtist,
} from "@models/search-release";
import { SearchTrack, Artist as SearchTrackArtist } from "@models/search-track";
import { Track, Artist as TrackArtist } from "@models/track";

export const DEFAULT_SEARCH_ALL_COUNT = "15";

export const DEFAULT_SEARCH_COUNT = "150";

export const MAX_SEARCH_COUNT = 150;

export const SEARCH_MAPPINGS: Record<string, string> = {
	genre: "genre_id",
	sub_genre: "sub_genre_id",
	label: "label_id",
	artists: "artist_id",
	key: "key_id",
	type: "type_id",
	bpm: "bpm",
	release_date: "release_date",
	label_name: "label_name",
	artist_name: "artist_name",
};

export const SEARCH_PATHS = [
	"tracks",
	"releases",
	"artists",
	"labels",
	"charts",
];

export const SORT_MAP: Record<string, string> = {
	title: "name",
	label: "label",
	genre: "genre",
	release: "release_date",
};

export const mapSearchReleases = (inputData?: SearchRelease[]): Release[] => {
	if (!inputData) return [];
	return inputData.map((searchRelease: SearchRelease): Release => {
		return {
			artists: searchRelease.artists.map(
				(artist: SearchReleaseArtist): ReleaseArtist => {
					return {
						id: artist.artist_id,
						name: artist.artist_name,
						slug: convertToSlug(artist.artist_name),
						url: "",
						image: { id: 0, uri: "", dynamic_uri: "" },
					};
				},
			),
			bpm_range: { min: 0, max: 0 },
			catalog_number: searchRelease.catalog_number,
			enabled: true,
			encoded_date: searchRelease.encoded_date.toString(),
			exclusive: searchRelease.exclusive === 1 ? true : false,
			id: searchRelease.release_id,
			image: {
				id: 0,
				uri: searchRelease.release_image_uri,
				dynamic_uri: searchRelease.release_image_uri,
			},
			label: {
				id: searchRelease.label.label_id,
				name: searchRelease.label.label_name,
				image: { id: 0, uri: "", dynamic_uri: "" },
				slug: convertToSlug(searchRelease.label.label_name),
			},
			name: searchRelease.release_name,

			pre_order: searchRelease.is_pre_order || false,
			pre_order_date: searchRelease.pre_order_date?.toString(),
			price: searchRelease.price,
			price_override_firm: false,
			publish_date: searchRelease.publish_date.toString(),
			remixers: [],
			slug: convertToSlug(searchRelease.release_name),
			track_count: searchRelease.tracks.length,
			upc: searchRelease.upc,
			updated: searchRelease.update_date.toString(),
			url: "",
			is_hype: searchRelease.is_hype,
		};
	});
};

export const mapSearchCharts = (inputData?: SearchChart[]): Chart[] => {
	if (!inputData) return [];
	return inputData.map((searchChart: SearchChart): Chart => {
		return {
			add_date: searchChart.create_date.toString().split("T")[0],
			artist: {
				id: searchChart.artist_id || 0,
				name: searchChart.artist_name || "",
				slug: convertToSlug(searchChart.artist_name || ""),
			},
			change_date: searchChart.update_date.toString().split("T")[0],
			enabled: searchChart.enabled === 1 ? true : false,
			genres: searchChart.genres.map(
				(genre: SearchChartGenre): Genre => ({
					id: parseInt(genre.genre_id),
					name: genre.genre_name,
					slug: convertToSlug(genre.genre_name),
					url: "",
				}),
			),
			id: searchChart.chart_id,
			image: {
				id: 0,
				uri: searchChart.chart_image_uri,
				dynamic_uri: searchChart.chart_image_uri,
			},
			is_approved: searchChart.is_approved === 1 ? true : false,
			is_indexed: searchChart.is_indexed === 1 ? true : false,
			is_published: searchChart.is_published === 1 ? true : false,

			name: searchChart.chart_name,
			person: {
				id: searchChart.person_id || 0,
				owner_image: "",
				owner_name: searchChart.person_username || "",
				owner_slug: "",
				owner_type: "",
			},
			publish_date: searchChart.publish_date?.toString().split("T")[0] || "",
			slug: convertToSlug(searchChart.chart_name),
			track_count: searchChart.track_count,
			url: "",
		};
	});
};

export const mapSearchTracks = (inputData?: SearchTrack[]): Track[] => {
	if (!inputData) return [];
	return inputData.map((searchTrack: SearchTrack): Track => {
		return {
			artists: searchTrack.artists.map(
				(item: SearchTrackArtist): TrackArtist => {
					return {
						id: item.artist_id,
						name: item.artist_name,
						slug: convertToSlug(item.artist_name),
						url: "",
						image: { id: 0, uri: "", dynamic_uri: "" },
					};
				},
			),
			publish_status: searchTrack.publish_status,
			available_worldwide: searchTrack.available_worldwide === 1 ? true : false,
			bpm: searchTrack.bpm,
			catalog_number: searchTrack.catalog_number,
			current_status: {
				id: searchTrack.current_status.current_status_id,
				name: searchTrack.current_status.current_status_name,
				url: "",
			},
			encoded_date: "",
			exclusive: false,
			genre: {
				id: parseInt(searchTrack.genre[0].genre_id),
				name: searchTrack.genre[0].genre_name,
				slug: convertToSlug(searchTrack.genre[0].genre_name),
				url: "",
			},
			id: searchTrack.track_id,
			image: {
				id: 0,
				uri: searchTrack.track_image_uri,
				dynamic_uri: searchTrack.track_image_uri,
			},
			is_available_for_streaming:
        searchTrack.is_available_for_streaming === 1 ? true : false,
			isrc: searchTrack.isrc,
			key: {
				camelot_number: 0,
				camelot_letter: "",
				chord_type: { id: searchTrack.chord_type_id, name: "", url: "" },
				id: searchTrack.key_id,
				is_sharp: false,
				is_flat: false,
				letter: "",
				name: searchTrack.key_name,
				url: "",
			},
			length: searchTrack.length?.toString(),
			length_ms: searchTrack.length,
			mix_name: searchTrack.mix_name,
			name: searchTrack.track_name,
			new_release_date: searchTrack.release_date.toString().split("T")[0],
			pre_order: searchTrack.is_pre_order || false,
			pre_order_date:
        searchTrack.pre_order_date?.toString().split("T")[0] || "",
			price: searchTrack.price,
			publish_date: searchTrack.publish_date.toString().split("T")[0],
			release: {
				id: searchTrack.release.release_id,
				name: searchTrack.release.release_name,
				image: {
					id: 0,
					uri: searchTrack.release.release_image_uri,
					dynamic_uri: searchTrack.release.release_image_uri,
				},
				label: {
					id: searchTrack.label.label_id,
					name: searchTrack.label.label_name,
					image: {
						id: 0,
						uri: "",
						dynamic_uri: "",
					},
					slug: convertToSlug(searchTrack.label.label_name),
				},
				slug: convertToSlug(searchTrack.release.release_name),
			},
			remixers: [],
			sale_type: {
				id: 0,
				name: searchTrack.sale_type,
				url: "",
			},
			sample_url: "",
			sample_start_ms: 0,
			sample_end_ms: Math.min(120000, searchTrack.length),
			slug: convertToSlug(searchTrack.track_name),
			// stream_url?: "",
			sub_genre: {
				id: searchTrack.sub_genre ?
						parseInt(searchTrack.sub_genre.sub_genre_id) :
					0,
				name: searchTrack.sub_genre ? searchTrack.sub_genre.sub_genre_name : "",
				slug: convertToSlug(
					searchTrack.sub_genre ? searchTrack.sub_genre.sub_genre_name : "",
				),
				url: "",
			},
			url: "",
			is_hype: false,
		};
	});
};

export const mapSearchArtists = (inputData?: SearchArtist[]): Artist[] => {
	if (!inputData) return [];
	return inputData?.map((searchArtist: SearchArtist): Artist => {
		return {
			enabled: searchArtist.enabled === 1 ? true : false,
			id: searchArtist.artist_id,
			image: {
				id: 0,
				uri: searchArtist.artist_image_uri,
				dynamic_uri: searchArtist.artist_image_uri,
			},
			name: searchArtist.artist_name,
			slug: convertToSlug(searchArtist.artist_name),
		};
	});
};

export const mapSearchLabels = (inputData?: SearchLabel[]): Label[] => {
	if (!inputData) return [];
	return inputData?.map((searchLabel: SearchLabel): Label => {
		return {
			enabled: searchLabel.enabled === 1 ? true : false,
			has_exclusive_contract: false,
			id: searchLabel.label_id,
			image: {
				id: 0,
				uri: searchLabel.label_image_uri,
				dynamic_uri: searchLabel.label_image_uri,
			},
			is_available_for_hype:
        searchLabel.is_available_for_hype === 1 ? true : false,
			is_available_for_bundle_subscription: false,
			is_available_for_pre_order: false,
			is_available_for_streaming:
        searchLabel.is_available_for_streaming === 1 ? true : false,
			is_included_in_rightsflow:
        searchLabel.is_included_in_rightsflow === 1 ? true : false,
			name: searchLabel.label_name,
			slug: convertToSlug(searchLabel.label_name),
			updated: searchLabel.update_date.toString().split("T")[0],
			hype_eligible: searchLabel.is_available_for_hype === 1 ? true : false,
			hype_active: false,
		};
	});
};

export const KEYS = [
	{
		id: 23,
		name: "A Major",
	},
	{
		id: 8,
		name: "A Minor",
	},
	{
		id: 25,
		name: "A# Major",
	},
	{
		id: 26,
		name: "A# Minor",
	},
	{
		id: 16,
		name: "Ab Major",
	},
	{
		id: 1,
		name: "Ab Minor",
	},
	{
		id: 13,
		name: "B Major",
	},
	{
		id: 10,
		name: "B Minor",
	},
	{
		id: 18,
		name: "Bb Major",
	},
	{
		id: 3,
		name: "Bb Minor",
	},
	{
		id: 20,
		name: "C Major",
	},
	{
		id: 5,
		name: "C Minor",
	},
	{
		id: 27,
		name: "C# Major",
	},
	{
		id: 28,
		name: "C# Minor",
	},
	{
		id: 22,
		name: "D Major",
	},
	{
		id: 7,
		name: "D Minor",
	},
	{
		id: 29,
		name: "D# Major",
	},
	{
		id: 30,
		name: "D# Minor",
	},
	{
		id: 15,
		name: "Db Major",
	},
	{
		id: 12,
		name: "Db Minor",
	},
	{
		id: 24,
		name: "E Major",
	},
	{
		id: 9,
		name: "E Minor",
	},
	{
		id: 17,
		name: "Eb Major",
	},
	{
		id: 2,
		name: "Eb Minor",
	},
	{
		id: 19,
		name: "F Major",
	},
	{
		id: 4,
		name: "F Minor",
	},
	{
		id: 14,
		name: "F# Major",
	},
	{
		id: 11,
		name: "F# Minor",
	},
	{
		id: 21,
		name: "G Major",
	},
	{
		id: 6,
		name: "G Minor",
	},
	{
		id: 31,
		name: "G# Major",
	},
	{
		id: 32,
		name: "G# Minor",
	},
	{
		id: 33,
		name: "Gb Major",
	},
	{
		id: 34,
		name: "Gb Minor",
	},
];

export const TRACK_SORT_OPTIONS: {
	name: string;
	value: string;
	queryStr: string;
	nextQueryStr: string | undefined;
	icon: string | undefined;
}[] = [
	{
		name: "SortOptions.NewestOldest",
		value: "release-desc",
		queryStr: "-release_date",
		nextQueryStr: undefined,
		icon: "B",
	},
	{
		name: "SortOptions.OldestNewest",
		value: "release-asc",
		queryStr: "release_date",
		nextQueryStr: "release-desc",
		icon: "A",
	},
	{
		name: "SortOptions.TitleAZ",
		value: "title-asc",
		queryStr: "name",
		nextQueryStr: "title-desc",
		icon: "A",
	},
	{
		name: "SortOptions.TitleZA",
		value: "title-desc",
		queryStr: "-name",
		nextQueryStr: undefined,
		icon: "B",
	},
	{
		name: "SortOptions.LabelAZ",
		value: "label-asc",
		queryStr: "label",
		nextQueryStr: "label-desc",
		icon: "A",
	},
	{
		name: "SortOptions.LabelZA",
		value: "label-desc",
		queryStr: "-label",
		nextQueryStr: undefined,
		icon: "B",
	},
	{
		name: "SortOptions.GenreAZ",
		value: "genre-asc",
		queryStr: "genre",
		nextQueryStr: "genre-desc",
		icon: "A",
	},
	{
		name: "SortOptions.GenreZA",
		value: "genre-desc",
		queryStr: "-genre",
		nextQueryStr: undefined,
		icon: "B",
	},
];

export const handleTableSort = (
	data: Track[],
	sortBy: string | string[] | undefined,
) => {
	if (!data) return data;
	let loData;
	switch (sortBy) {
		case "release_date":
			loData = data.sort((a, b) =>
				new Date(a.new_release_date) > new Date(b.new_release_date) ? 1 : -1,
			);
			break;
		case "-release_date":
			loData = data
				.sort((a, b) =>
					new Date(a.new_release_date) > new Date(b.new_release_date) ? 1 : -1,
				)
				.reverse();
			break;
		case "name":
			loData = data.sort((a, b) =>
				a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
			);
			break;
		case "-name":
			loData = data
				.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
				.reverse();
			break;
		case "label":
			loData = data.sort((a, b) =>
				a.release.label.name.toLowerCase() > b.release.label.name.toLowerCase() ?
					1 :
						-1,
			);
			break;
		case "-label":
			loData = data
				.sort((a, b) =>
					a.release.label.name.toLowerCase() >
					b.release.label.name.toLowerCase() ?
						1 :
							-1,
				)
				.reverse();
			break;
		case "genre":
			loData = data.sort((a, b) =>
				a.genre.name.toLowerCase() > b.genre.name.toLowerCase() ? 1 : -1,
			);
			break;
		case "-genre":
			loData = data
				.sort((a, b) =>
					a.genre.name.toLowerCase() > b.genre.name.toLowerCase() ? 1 : -1,
				)
				.reverse();
			break;
		default:
			loData = data;
	}

	return loData;
};
