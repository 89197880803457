import styled from "styled-components";

export const CartItem = styled.div`
  position: relative;
  z-index: 402;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.0025em;
  color: ${(props) => props.theme.colors.neutrals.primary.white};

  &:nth-child(2) {
    a {
      padding-top: 1rem;
    }
  }

  &:last-child {
    a {
      padding-bottom: 1rem;
    }
  }

  a {
    display: flex;
    flex-direction: row;

    gap: 4px;
    cursor: pointer;
    padding: 0.5rem 1rem;
    width: 100%;
    background: ${(props) => props.theme.colors.neutrals.primary[800]};
    color: ${(props) => props.theme.colors.white[1]};
    border-radius: 0.25rem;

    @media ${(props) => props.theme.device.md} {
      color: ${(props) => props.theme.colors.neutrals.primary.graytext};
      padding: 0.5rem !important;
      background-color: inherit;

      &:hover {
        color: ${(props) => props.theme.colors.white[1]};
        background-color: ${(props) => props.theme.colors.neutrals.primary[800]};
      }
    }

    span.name {
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      svg {
        margin-top: 2px;
        margin-right: 4px;
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;

  border-bottom: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  padding: 1rem;
`;

interface WrapperProps {
	left: number;
	top: number;
}

export const Selector = styled.div<WrapperProps>`
  position: fixed;
  width: 360px;
  z-index: 401;
  margin-top: 15px;
  left: calc((100% - 360px) / 2);
  top: calc(100vh / 4);

  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.colors.neutrals.primary[800]};;

  @media ${(props) => props.theme.device.md} {
    left: ${(props) =>
		props.left < 260 ? "0px" : `calc(${props.left}px - 260px)`};
    top: ${(props) => props.top}px;
    width: 260px;
    min-width: 260px;
    border-radius: 0.5rem;
    background: ${(props) => props.theme.colors.neutrals.primary[900]};
    padding: 0.375rem;
  }
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  cursor: default;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  @media ${(props) => props.theme.device.md} {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const Wrapper = styled.div`
  align-items: start;
  justify-content: center;
  overflow: hidden;
  padding-top: 180px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  opacity: 1;
  z-index: 400;
`;
