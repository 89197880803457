import Header from "@components/layouts/header/Header";
import { AddToPlaylistModal, AuthModal } from "@components/modals";

import FeedbackButton from "@components/FeedbackButton";
import { CartSelector } from "@components/interaction/CartSelector";
import { UpgradeTrackSelector } from "@components/interaction/UpgradeTrackSelector";
import Footer from "@components/layouts/footer/Footer";
import Sidebar from "@components/layouts/sidebar/Sidebar";
import { Notifications } from "@components/notifications";
import ANCHOR_IDS from "@lib/constants/anchor-ids";
import { LayoutContext } from "@lib/context/layout/LayoutContext";
import { useSessionContext } from "@lib/context/session";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { getMyAccountQuery } from "@lib/network/my";
import {
	pushSuccessfulLoginEvent,
	pushSuccessfulRegistrationEvent,
} from "@lib/utils/dataLayer";
import { Cart, CartModalState, UpgradeTrackState } from "@models/Cart";
import { Track } from "@models/track";
import { device } from "@styles/theme";
import { useQuery } from "@tanstack/react-query";
import Head from "next/head";
import Router from "next/router";
import React, { useEffect, useState } from "react";
import { Main, MainContent, MainWrapper } from "./MainLayout.style";

type MainLayoutProps = {
	title?: string;
	metaDescription?: string;
	children: React.ReactNode[] | React.ReactNode;
	hasHeader?: boolean;
	hasFooter?: boolean;
	hasDesktopSidebar?: boolean;
	noSuffix?: boolean;
	socialImage?: string;
};

const Component = ({
	children,
	title,
	metaDescription,
	hasHeader = true,
	hasFooter = true,
	hasDesktopSidebar = true,
	noSuffix = false,
	socialImage,
}: MainLayoutProps) => {
	const [showSidebar, setShowSidebar] = useState(false);

	const [authModal, setAuthModal] = useState({
		visible: false,
		next: "",
		onSuccess: () => { },
	});

	const [addToPlaylistModal, setAddToPlaylistModal] = useState<{
		visible: boolean;
		id: number;
		type: string;
		tracks?: Track[];
	}>({
		visible: false,
		id: 0,
		type: "",
		tracks: [],
	});

	const [cartModal, setCartModal] = useState<CartModalState>({
		show: false,
		top: 0,
		left: 0,
		carts: [],
		selectedCarts: [],
		onSelectedCart: () => { },
	});

	const [upgradeTrackModal, setUpgradeTrackModal] = useState<UpgradeTrackState>(
		{
			show: false,
			top: 0,
			left: 0,
			carts: [],
			handleTrackUpgradeClick: () => { },
		},
	);

	const { getAccessToken, getIsSessionValid, session } = useSessionContext();
	const accessToken = getAccessToken();
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });
	const { data: myAccount } = useQuery<any>(getMyAccountQuery({
		accessToken,
		enabled: isLoggedInSessionValid,
	}));
	const isXl = useMediaQuery({ query: device.xl });

	const authenticateLink = (next: string, onSuccess?: () => void) => {
		setAuthModal({
			visible: true,
			next: next,
			onSuccess: () => onSuccess && onSuccess(),
		});
	};

	const showCartSelector = ({
		show,
		top,
		left,
		carts,
		selectedCarts,
		onSelectedCart,
	}: {
		show: boolean;
		top: number;
		left: number;
		carts: Cart[];
		selectedCarts: number[];
		onSelectedCart: (cartId: number) => void;
	}) => {
		setCartModal({
			show: show,
			top: top,
			left: left,
			carts: carts || [],
			selectedCarts: selectedCarts,
			onSelectedCart: onSelectedCart,
		});
	};

	const showUpgradeTrackSelector = ({
		show, top, left, carts, handleTrackUpgradeClick,
	}: {
		show: boolean;
		top: number;
		left: number;
		carts: Cart[];
		handleTrackUpgradeClick: (cartId: number, audioFormatId: number) => void;
	}) => {
		setUpgradeTrackModal({
			show: show,
			top: top,
			left: left,
			carts: carts || [],
			handleTrackUpgradeClick,
		});
	};

	const showAddToPlaylist = ({ id, type, tracks }: { id: number; type: string; tracks?: Track[] }) => {
		setAddToPlaylistModal({
			visible: true,
			id: id,
			type: type,
			tracks: tracks,
		});
	};

	const handleAuthModalOnClose = () => {
		setAuthModal({ visible: false, next: "", onSuccess: () => { } });
	};

	const handleAddToPlaylistModalOnClose = () => {
		setAddToPlaylistModal({
			id: 0,
			type: "",
			visible: false,
			tracks: [],
		});
	};

	const disableOverflow =
		authModal.visible ||
		cartModal.show ||
		upgradeTrackModal.show ||
		addToPlaylistModal.visible;

	useEffect(() => {
		const body = document.querySelector("body");
		if (body) {
			if (disableOverflow) {
				body.style.overflow = "hidden";
			} else {
				body.style.overflow = "auto";
			}
		}
	}, [disableOverflow]);

	// Data Layer event for successful login / signup
	useEffect(() => {
		const personId = session?.introspect?.person_id;
		const subscription = session?.introspect?.subscription || null;
		if (personId) {
			const event = {
				personId,
				subscription,
				isLoggedIn: true,
			};
			if (Router.pathname === "/account/signup") {
				pushSuccessfulRegistrationEvent(event);
			} else {
				pushSuccessfulLoginEvent(event);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session?.introspect?.user_id]);

	const pageTitle = title ?
		`${title}${noSuffix ? "" : " :: Beatport"}` :
		"Beatport";
	const pageDescription = metaDescription ?
		metaDescription :
		"Download and listen to new, exclusive, electronic dance music and house tracks. Available on mp3 and wav at the world’s largest store for DJs.";
	const pageImage = socialImage ?
		socialImage :
		"https://www.beatport.com/images/beatport-social-image.png";

	return (
		<LayoutContext.Provider
			value={{
				showSidebar,
				setShowSidebar,
				authenticateLink,
				showCartSelector,
				showUpgradeTrackSelector,
				showAddToPlaylist,
			}}
		>
			<Head>
				<title>{pageTitle}</title>
				<meta property="og:title" content={pageTitle} />
				<meta property="og:description" content={pageDescription} />
				<meta property="og:image" content={pageImage} />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={pageTitle} />
				<meta name="twitter:description" content={pageDescription} />
				<meta name="twitter:image" content={pageImage} />
				<meta name="description" content={pageDescription} />
				<link rel="icon" type="image/x-icon" href="/images/favicon-48x48.png" />
				<link
					href="https://js.recurly.com/v4/recurly.css"
					rel="stylesheet"
					type="text/css"
				/>
			</Head>
			<div>
				{hasHeader && (
					<div id={ANCHOR_IDS.HEADER}>
						<Header />
					</div>
				)}
				<MainWrapper>
					<Main $hasHeader={hasHeader}>
						<Notifications />
						<Sidebar showDesktop={hasDesktopSidebar} />
						<MainContent $showSidebar={hasDesktopSidebar && isXl}>
							{children}
						</MainContent>
					</Main>
				</MainWrapper>
				{hasFooter && (
					<div>
						<Footer />
					</div>
				)}
			</div>

			{cartModal.show && (
				<CartSelector
					x={cartModal.left}
					y={cartModal.top}
					carts={cartModal.carts}
					onCartSelected={cartModal.onSelectedCart}
					selectedCarts={cartModal.selectedCarts}
					onLeave={function (): void {
						showCartSelector({
							show: false,
							top: 0,
							left: 0,
							carts: [],
							selectedCarts: [],
							onSelectedCart: () => { },
						});
					}}
				/>
			)}

			<FeedbackButton
				userIntrospect={session?.introspect || null}
				userAccount={myAccount}
			/>

			{upgradeTrackModal.show && (
				<UpgradeTrackSelector
					x={upgradeTrackModal.left}
					y={upgradeTrackModal.top}
					carts={upgradeTrackModal.carts}
					onLeave={function (): void {
						showUpgradeTrackSelector({
							show: false,
							top: 0,
							left: 0,
							carts: [],
							handleTrackUpgradeClick: () => { },
						});
					}}
					handleTrackUpgradeClick={upgradeTrackModal.handleTrackUpgradeClick}
				/>
			)}

			<AuthModal
				onClose={handleAuthModalOnClose}
				show={authModal.visible}
				next={authModal.next}
				onSuccess={authModal.onSuccess}
			/>

			<AddToPlaylistModal
				id={addToPlaylistModal.id}
				type={addToPlaylistModal.type}
				tracks={addToPlaylistModal.tracks}
				show={addToPlaylistModal.visible}
				onClose={handleAddToPlaylistModalOnClose}
			/>
		</LayoutContext.Provider>
	);
};

export default Component;
