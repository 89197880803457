import styled from "styled-components";
import { ArtworkAndControls, Wrapper } from "../Tables.shared.style";

export const PlaylistTracksWrapper = styled(Wrapper)`
  position: relative;
  &.loading {
    opacity: 0.5;
    select,
    div,
    input {
      filter: blur(0.25rem);
    }
    &::before {
      z-index: 10;
      position: absolute;
      content: "Updating...";
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: ${(props) => props.theme.fonts.aeonik};
      font-style: normal;
      font-weight: 600;
      font-size: 500;
    }
  }

  .list-wrapper {
    width: 100%;
  }

  .info {
    display: block;
    padding: 0.08rem 0.15rem 0 0;
  }

  span.tombstoned {
    opacity: 0.15;
  }

  .label {
    width: 200px;
    min-width: 200px;
  }

  .date {
    width: 120px;
    min-width: 120px;
  }

  .bpm {
    width: 150px;
    min-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .card {
    width: 120px;
    min-width: 120px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    button.reorder {
      cursor: grab;
    }
  }

  .scrollable {
    max-height: 500px;
    position: relative;
    overflow: auto;
  }

  @media ${(props) => props.theme.device.xsm} {

    .card {
      width: 135px;
      min-width: 135px;
    }

    .label {
      display: none;
    }
    .bpm {
      display: none;
    }
    .date {
      display: none;
    }

    .add-to-cart {
      display: none;
    }

    .no-controls {
      .add-to-cart {
        display: block;
      }
    }
    ${ArtworkAndControls} {
      a.artwork {
        min-width: 40px;
      }
      span.fade,
      span.tombstoned {
        display: none;
      }
    }
  }

  @media ${(props) => props.theme.device.md} {
    &.numbers {
      .controls {
        width: 10.625rem;
        min-width: 10.625rem;
      }
    }
    ${ArtworkAndControls} {
      a.artwork {
        min-width: 40px;
      }
      span.fade,
      span.tombstoned {
        display: block;
      }
    }

    .add-to-cart {
      display: flex;
    }
  }

  @media ${(props) => props.theme.device.lg} {
    .label {
      display: block;
    }
    .bpm {
      display: block;
    }

    .card {
      width: 135px;
      min-width: 135px;
    }
  }
  @media ${(props) => props.theme.device.xl} {
    .date {
      display: block;
    }
  }
`;
