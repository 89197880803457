import {
	HEADER_HEIGHT,
	SIDEBAR_DESKTOP_MAX_WIDTH,
	SIDEBAR_DESKTOP_MIN_WIDTH,
	SIDEBAR_DESKTOP_WIDTH,
} from "@lib/css";
import styled from "styled-components";

export const DesktopSidebar = styled.div`
  display: flex;
  flex-direction: row;
  min-width: ${SIDEBAR_DESKTOP_MIN_WIDTH};
  width: ${SIDEBAR_DESKTOP_WIDTH};
  max-width: ${SIDEBAR_DESKTOP_MAX_WIDTH};
  position: sticky;  
  max-height: calc(100vh - (${HEADER_HEIGHT} + 7.625rem));
  top: calc(${HEADER_HEIGHT} + 1.75rem);

  .sidebar-content {
    background-color: ${(props) => props.theme.colors.neutrals.primary[600]};
    flex: 1;
  }

  .sidebar-resizer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 6px;
    justify-self: flex-end;
    cursor: col-resize;
    resize: horizontal;

    &:hover {
      width: 3px;

      background-color: ${(props) => props.theme.colors.neutrals.primary[900]};
    }
  }
`;

export const MobileSidebar = styled.div`
  z-index: 150;
  position: fixed;
  top: ${HEADER_HEIGHT};
  left: 0;
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT});
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.neutrals.primary[800]};
  @media ${(props) => props.theme.device.xl} {
    display: none;
  }

  ul li.subscribe {
    display: flex;
    width: 100%;
    padding: 12.5px 25px;
    a {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;

export const MobileSidebarDivider = styled.hr`
  color: ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  margin: 1.5rem 0;
`;

export const MobileSubMenuWrapper = styled.div`
  z-index: 200;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overscroll-behavior: contain;
  background-color: ${(props) => props.theme.colors.neutrals.primary[800]};
`;

export const MobileSubMenuContent = styled.div`
  padding: 0 21px;
  font-size: 1.25rem;
`;

export const SidebarLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  padding: 12.5px 25px;

  &.genre {
    text-transform: uppercase;
    padding-left: 0;
  }

  @media ${(props) => props.theme.device.xl} {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.neutrals.primary[800]};
    padding: 12.5px 21px;
    font-size: 0.9rem;
  }

  svg {
    margin-right: 10px;
    width: 1rem !important;
    height: 1rem !important;
  }

  .forward-arrow {
    margin-left: 10px;
  }

  :hover {
    cursor: pointer;
  }
`;

export const SidebarExternalLinkElement = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  padding: 12.5px 25px;

  svg {
    margin-left: 10px;
    width: 1.2rem !important;
    height: 1.2rem !important;
    stroke: ${(props) => props.theme.colors.neutrals.primary.graytext};
  }
`;

export const SidebarLinkActiveBar = styled.div`
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.colors.green[300].accent};
  left: 0;
`;

export const HorizontalBar = styled.div`
  margin: 0.5rem auto;
  background-color: ${(props) =>
		props.theme.colors.neutrals.secondary.gray.divider1};
  width: calc(100% - 2 * 0.9375rem);
  height: 1px;
`;

export const SubSectionContainer = styled.div`
  padding: 0;
  overflow: scroll;
  flex: 1;

  & .element {
    margin: 0.8rem 0;
  }

  & .mobile-playlist-element {
    margin-bottom: 1.5rem;

    &:first-of-type {
      margin-top: 1rem;
    }
  }

  & .extra-margin {
    margin-left: 0.5625rem;
    margin-right: 0.5625rem;
  }

  @media ${(props) => props.theme.device.xl} {
    padding: 0 0.9375rem;
  }
`;

export const PlaylistSectionTitle = styled.h3`
  font-family: "Aeonik", sans-serif;
  font-size: 0.75rem;
  text-transform: uppercase;
  margin: 0 0.5625rem;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  display: none;

  @media ${(props) => props.theme.device.xl} {
    display: block;
  }
`;

export const CreatePlaylist = styled.div`
  a {
    display: flex;
    align-items: center;
  }
  color: ${(props) => props.theme.colors.green[300].accent};
  font-weight: 500;
  font-size: 1rem;

  @media ${(props) => props.theme.device.xl} {
    font-size: 0.9rem;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const PlaylistElement = styled.li`
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.neutrals.primary.white};
  cursor: pointer;
  transition: color 150ms ease-out;

  :hover {
    color: white;
  }

  @media ${(props) => props.theme.device.xl} {
    font-size: 0.875rem;
    color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  }

  a {
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const PlaylistSectionContent = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  min-height: 0;

  & .element {
    margin: 0.8rem 0;
  }

  & .mobile-playlist-element {
    margin-bottom: 1.5rem;

    &:first-of-type {
      margin-top: 1rem;
    }
  }

  & .extra-margin {
    margin-left: 0.5625rem;
    margin-right: 0.5625rem;
  }

  @media ${(props) => props.theme.device.xl} {
    padding: 0 0.9375rem;
  }

  &.content {
    height: 60px;
    min-height: 60px;
    max-height: 60px;
  }

  &.scrollable {
    flex-grow: 1;
    overflow: auto;
    /* for Firefox */
    min-height: 0;
  }

  /* Initially hide the scrollbar */
  &.scrollable::-webkit-scrollbar {
    width: 0px;
  }

  &.scrollable::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.neutrals.primary[600]};
  }

  &.scrollable::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.neutrals.primary[750]};
  }

  /* Show scrollbar on hover */
  &.scrollable:hover::-webkit-scrollbar {
    width:5px;
  }
`;

export const PlaylistSectionContainer = styled.div`
  position: relative;
  height: calc(100% - 207px);
  width: 100%;

  div.wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    display: flex;
    flex-direction: column;
  }
`;
