import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { useCart } from "@lib/context/cart";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { Cart } from "@models/Cart";
import { device } from "@styles/theme";
import { removeZeroDecimals } from "@lib/utils";
import { useTranslation } from "next-i18next";
import {
	CartItem,
	Header,
	Overlay,
	Selector,
	Wrapper,
} from "./UpgradeTrackSelector.style";

interface Props {
	x: number;
	y: number;
	onLeave: () => void;
	carts: Cart[];
	handleTrackUpgradeClick: (cartId: number, audioFormatId: number) => void;
}
const UpgradeTrackSelector: React.FC<Props> = ({
	x,
	y,
	onLeave,
	carts,
	handleTrackUpgradeClick,
}) => {
	const isMd = useMediaQuery({ query: device.md });
	const { state } = useCart();
	const { audioFormats } = state;
	const { t } = useTranslation("translation");

	const cart = carts.filter((cart) => cart.name === "cart")[0];
	return (
		<Wrapper>
			<Overlay
				onClick={() => {
					onLeave();
				}}
			>
				<Selector
					onMouseLeave={() => {
						onLeave();
					}}
					top={y}
					left={x}
				>
					{!isMd && (
						<Header>
							<span className="title">{`${t("Upgrade")} ${t("Track")}`} </span>
							<span className="action" onClick={() => onLeave()}>
								<SpriteIcon id="close-x" width="18" height="19" />
							</span>
						</Header>
					)}
					{Object.values(audioFormats).map((af, key) => {
						if (af.id === 1) return;
						return (
							<CartItem key={key}>
								<a
									title={`Upgrade to ${af.name.toUpperCase()}`}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										handleTrackUpgradeClick(cart.id, af.id);
									}}
								>
									<span className="name">
										<SpriteIcon id="cart-main" /> Main Cart
									</span>
									<span className="action-upgrade">
										{af.name.toUpperCase()} - {removeZeroDecimals(af.fees["item_purchase"].display)}
									</span>
								</a>
							</CartItem>
						);
					})}
				</Selector>
			</Overlay>
		</Wrapper>
	);
};

export default UpgradeTrackSelector;
