import styled from "styled-components";

export const Control = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: inline-end;

  &.album-only {
    span.album {
      min-width: 100px;
      width: 100px;
      background: ${(props) => props.theme.colors.neutrals.primary.graytext};
      color: ${(props) => props.theme.colors.neutrals.primary.white};
      border-radius: 4px;
      padding: 4px;

      font-family: ${(props) => props.theme.fonts.aeonik};
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }
  }

  span.price {
    min-width: 3.75rem;
    background: ${(props) => props.theme.colors.pink[300].accent};
    color: ${(props) => props.theme.colors.neutrals.primary.white};
    border-radius: 4px 0px 0px 4px;
    padding: 0.25rem 0.5rem;

    font-family: ${(props) => props.theme.fonts.aeonik};
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }

  span.arrow {
    background: ${(props) => props.theme.colors.pink[300].stroke};
    border-radius: 0px 4px 4px 0px;
    padding: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    i {
      margin: 4px;
      display: inline-block;
      vertical-align: middle;

      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid
        ${(props) => props.theme.colors.neutrals.primary.white};
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.preorder {
    span.price {
      background: ${(props) => props.theme.colors.orange};
    }

    span.arrow {
      background: ${(props) => props.theme.colors.yellow[300].stroke};
    }
  }

  &.in-cart {
    span.price {
      background: ${(props) => props.theme.colors.neutrals.primary.graytext};
      color: ${(props) => props.theme.colors.neutrals.primary.white};
    }

    span.arrow {
      background: ${(props) =>
			props.theme.colors.neutrals.secondary.gray.divider2};
      color: ${(props) => props.theme.colors.neutrals.primary.white};
    }
  }
`;
