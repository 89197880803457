import styled from "styled-components";

interface BoxWrapperProps {
	$justifyContent?: string;
	$alignItems?: string;
	$direction?: string;
	$margin?: string;
}

export const BoxWrapper = styled.div<BoxWrapperProps>`
  display: flex;
  flex-direction: ${(props) => props.$direction || "column"};
  justify-content: ${(props) => props.$justifyContent || "center"};
  align-items: ${(props) => props.$alignItems || "flex-start"};
  padding: 20px;
  margin: ${(props) => props.$margin || ""};
  border-radius: 4px;

  &.default {
    background-color: ${(props) => props.theme.colors.neutrals.primary[800]};
  }

  &.bg-primary {
    background-color: ${(props) => props.theme.colors.green[300].accent};
  }

  &.bg-secondary {
    background-color: ${(props) =>
		props.theme.colors.neutrals.primary.graytext};
  }

  &.bg-outline {
    border: 1px solid;
    border-image-source: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
      ),
      linear-gradient(
        0deg,
        ${(props) => props.theme.colors.green[300].accent},
        ${(props) => props.theme.colors.green[300].accent}
      );
  }

  &.bg-tertiary {
    background-color: ${(props) => props.theme.colors.neutrals.primary[600]};
  }

  &.bg-pink {
    background-color: ${(props) => props.theme.colors.pink[300].accent};
  }

  &.bg-blue {
    background-color: ${(props) => props.theme.colors.blue[500].accent};
  }

  &.bg-purple {
    background-color: ${(props) => props.theme.colors.purple[300].accent};
  }

  &.bg-danger {
    background-color: ${(props) => props.theme.colors.semantic.danger};
  }

  &.bg-warn {
    background-color: ${(props) => props.theme.colors.semantic.warn};
  }

  &.text-default {
    color: ${(props) => props.theme.colors.neutrals.primary.white};
  }

  &.text-primary {
    color: ${(props) => props.theme.colors.green[300].accent};
  }

  &.text-secondary {
    color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  }

  &.text-tertiary {
    color: ${(props) => props.theme.colors.neutrals.primary[600]};
  }

  &.text-pink {
    color: ${(props) => props.theme.colors.pink[300].accent};
  }

  &.text-blue {
    color: ${(props) => props.theme.colors.blue[500].accent};
  }

  &.text-purple {
    color: ${(props) => props.theme.colors.purple[300].accent};
  }

  &.text-danger {
    color: ${(props) => props.theme.colors.semantic.danger};
  }

  &.text-warn {
    color: ${(props) => props.theme.colors.semantic.warn};
  }

  &.w-100 {
    width: 100%;
  }

  &.w-90 {
    width: 90%;
  }

  &.w-80 {
    width: 80%;
  }

  &.w-75 {
    width: 75%;
  }

  &.w-70 {
    width: 70%;
  }

  &.w-60 {
    width: 60%;
  }

  &.w-50 {
    width: 50%;
  }

  &.w-40 {
    width: 40%;
  }

  &.w-30 {
    width: 30%;
  }

  &.w-25 {
    width: 25%;
  }

  &.w-20 {
    width: 20%;
  }

  &.w-10 {
    width: 10%;
  }

  &.size-xxx-small {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }

  &.size-xx-small {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
  }

  &.size-x-small {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &.size-small {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  &.size-medium {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &.size-large {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }

  &.size-x-large {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  &.size-xx-large {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  &.size-xxx-large {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
  }

  &.p-0 {
    padding: 0;
  }

  &.p-1 {
    padding: 1px;
  }

  &.p-2 {
    padding: 2px;
  }

  &.p-3 {
    padding: 3px;
  }
  &.p-4 {
    padding: 4px;
  }
  &.p-5 {
    padding: 5px;
  }
  &.p-6 {
    padding: 6px;
  }
  &.p-7 {
    padding: 7px;
  }
  &.p-8 {
    padding: 8px;
  }
  &.p-9 {
    padding: 9px;
  }
  &.p-10 {
    padding: 10px;
  }
  &.p-15 {
    padding: 15px;
  }
  &.p-20 {
    padding: 20px;
  }

  &.gap-0 {
    gap: 0;
  }

  &.gap-1 {
    gap: 1px;
  }

  &.gap-2 {
    gap: 2px;
  }

  &.gap-3 {
    gap: 3px;
  }
  &.gap-4 {
    gap: 4px;
  }
  &.gap-5 {
    gap: 5px;
  }
  &.gap-6 {
    gap: 6px;
  }
  &.gap-7 {
    gap: 7px;
  }
  &.gap-8 {
    gap: 8px;
  }
  &.gap-9 {
    gap: 9px;
  }
  &.gap-10 {
    gap: 10px;
  }
  &.gap-15 {
    gap: 15px;
  }
  &.gap-20 {
    gap: 20px;
  }
  &.gap-40 {
    gap: 40px;
  }
`;
