import { Trans } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { setCookie } from "nookies";
import { FunctionComponent } from "react";
import toast from "react-hot-toast/headless";
import { useTranslation } from "react-i18next";

import { LanguageData } from "@bptypes/preferences";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { captureException } from "@integrations/sentry";
import {
	AFEM_LOGO,
	AVAILABLE_LANGUAGES,
	BEATPORT_LOGO,
	DEFAULT_COOKIE_AGE,
	MOBILE_APP_LINKS,
} from "@lib/constants";
import {
	INTERNAL_LINKS,
	NETWORK_LINKS,
	SOCIAL_LINKS,
} from "@lib/constants/footer";
import { useSessionContext } from "@lib/context/session";
import { patchMyAccountRequest } from "@lib/network/my";
import { FooterLink } from "@models/constants";
import {
	BrandColumnContainer,
	Copyright,
	FooterContainer,
	GroupTitle,
	LinksColumnContainer,
	LinksGroup,
	LinksList,
	ListItem,
	LogoWrapper,
	MobileAppLinks,
	SocialLinksContainer,
} from "./Footer.style";

const FooterColumnBrand: FunctionComponent = () => {
	return (
		<BrandColumnContainer>
			<LogoWrapper>
				<Image
					src={BEATPORT_LOGO.image.path}
					alt={BEATPORT_LOGO.title}
					width={BEATPORT_LOGO.image.width}
					height={BEATPORT_LOGO.image.height}
				/>
				<Copyright>{BEATPORT_LOGO.copyright}</Copyright>
			</LogoWrapper>
			<SocialLinksContainer>
				{SOCIAL_LINKS.map(({ title, href, icon }) => (
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={href}
						key={title}
						aria-label={title}
					>
						<SpriteIcon
							id={icon.path}
							title={title}
							width={icon.width.toString()}
							height={icon.height.toString()}
						/>
					</a>
				))}
			</SocialLinksContainer>
			<div>
				<Link href={AFEM_LOGO.href} prefetch={false} data-testid={AFEM_LOGO.cypressTag}>
					<Image
						src={AFEM_LOGO.image.path}
						alt={AFEM_LOGO.title}
						width={AFEM_LOGO.image.width}
						height={AFEM_LOGO.image.height}
						style={{
							maxWidth: "100%",
							height: "auto",
						}}
					/>
				</Link>
			</div>
		</BrandColumnContainer>
	);
};

const FooterColumnLinks: FunctionComponent = () => {
	const router = useRouter();
	const { getAccessToken, getIsSessionValid } = useSessionContext();
	const accessToken = getAccessToken();
	const { t } = useTranslation("translation");
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });

	const handleLanguageSelect = async (code: string, locale: string) => {
		if (isLoggedInSessionValid) {
			try {
				const response = await patchMyAccountRequest({ preferences: { language: locale } }, accessToken);
				if (!response.success) throw new Error("Unable to update user language preferences");
			} catch (error: any) {
				captureException({
					error,
					tags: {
						file_name: "Footer.tsx",
						function_name: "handleLanguageSelect",
					},
				});
			}
		}
		// API update is for marketing purposes. If it fails, we still want to let user
		// change their language preferences
		setCookie(null, "NEXT_LOCALE", code, { maxAge: DEFAULT_COOKIE_AGE, path: "/" });
		toast.success(t("Account.Language.Updated"));
	};

	const LinksColumn = ({ label, links }: { label: string; links: FooterLink[] }) => {
		return (
			<LinksGroup key={label}>
				<GroupTitle>
					<Trans>{label}</Trans>
				</GroupTitle>
				<LinksList>
					{
						links.map(({ label, href }: FooterLink) => (
							<ListItem key={label}>
								<a
									className="listItemLink"
									target="_blank"
									rel="noopener noreferrer"
									href={href}
								>
									<Trans>{label}</Trans>
								</a>
							</ListItem>
						))
					}
				</LinksList>
			</LinksGroup>
		);
	};

	const LanguageSelector = () => {
		return (
			<LinksGroup key="Languages">
				<GroupTitle>
					<Trans>Languages</Trans>
				</GroupTitle>
				<LinksList>
					{
						AVAILABLE_LANGUAGES.map(({ code, label, locale }: LanguageData) => (
							<ListItem key={label}>
								<Link
									aria-label={t("Account.Language.ChangeTo", { language: label })}
									className="listItemLink"
									href={router.asPath}
									onClick={() => handleLanguageSelect(code, locale)}
									locale={code}
								>
									<Trans>{label}</Trans>
								</Link>
							</ListItem>
						))
					}
				</LinksList>
			</LinksGroup>
		);
	};

	return (
		<LinksColumnContainer>
			<LinksColumn label="Company" links={INTERNAL_LINKS} />
			<LinksColumn label="Network" links={NETWORK_LINKS} />
			<LanguageSelector />
		</LinksColumnContainer>
	);
};

const FooterColumnMobile: FunctionComponent = () => {
	return (
		<MobileAppLinks>
			{
				MOBILE_APP_LINKS.map(({ href, alt, ...imageProps }) => (
					<Link
						href={href}
						key={imageProps.src.toString()}
						target="_blank"
					>
						<Image {...imageProps} alt={alt} />
					</Link>
				))
			}
		</MobileAppLinks>
	);
};

const Footer: FunctionComponent = () => {
	return (
		<FooterContainer id="footer">
			<FooterColumnBrand />
			<FooterColumnLinks />
			<FooterColumnMobile />
		</FooterContainer>
	);
};

export default Footer;
