import { LanguageData } from "@bptypes/preferences";
import { SidebarIconKey, SidebarLinkProps } from "@bptypes/sidebar";
import { signOut } from "next-auth/react";
import { ImageProps } from "next/image";
import { LinkProps } from "next/link";

const CURRENT_YEAR = new Date().getFullYear();

export const DEFAULT_COOKIE_AGE = 30 * 24 * 60 * 60; // 30 days

export const DEFAULT_LOCALE = "en";

export const AVAILABLE_LANGUAGES: LanguageData[] = [
	{ code: "en", label: "English", locale: "en-US" },
	{ code: "es", label: "Español", locale: "es-ES" },
	{ code: "fr", label: "Français", locale: "fr-FR" },
	{ code: "de", label: "German", locale: "de-DE" },
	{ code: "it", label: "Italiana", locale: "it-IT" },
	{ code: "ja", label: "日本語", locale: "ja-JP" },
	{ code: "nl", label: "Nederlands", locale: "nl-NL" },
	{ code: "pt", label: "Português", locale: "pt-BR" },
];

export const AFFILIATE_TRACKING = {
	src: "https://loopmasters.postaffiliatepro.com/",
	accountId: "default1",
};

export const ACCOUNT_MENU_ITEMS = [
	{
		order: 1,
		label: "Account.Settings",
		href: "/account/profile",
		cypressTag: "header-subnav-link-accountsettings",
	},
	{
		order: 2,
		label: "MyCharts",
		href: "/dj/charts",
		cypressTag: "header-subnav-link-mycharts",
	},
	{
		order: 3,
		label: "Subscriptions.MySubscriptions",
		href: "/my-subscriptions",
		cypressTag: "header-subnav-link-mysubscriptions",
	},
	{
		order: 4,
		label: "Actions.Logout",
		route: "/",
		isSignOut: true,
		action: signOut,
		cypressTag: "header-subnav-link-logout",
	},
];

export const AFEM_LOGO = {
	title: "AFEM",
	href: "https://www.associationforelectronicmusic.org/afem-approved-dj-download-sites/",
	image: {
		path: "/images/afem.png",
		width: 128,
		height: 84,
	},
	cypressTag: "footer-link-afemlogo",
};

export const API_BASES = {
	api4: "api4",
	search: "search",
};

export const ARTIST_PLACEHOLDER = {
	title: "Artist",
	href: "https://geo-media.beatport.com/image_size/590x404/0dc61986-bccf-49d4-8fad-6b147ea8f327.jpg",
	image: {
		path: "/artist-placeholder.jpg",
		width: 219,
		height: 152,
	},
	cypressTag: "search-artistplaceholder",
};

// TODO: merge all of this audio format data into a single audio formats object??
export const AUDIO_FORMATS = {
	aiff: "AIFF",
	mp3: "MP3",
	wav: "WAV",
	flac: "FLAC",
};

export const AUDIO_FORMAT_IDS = [
	{ id: 1, name: AUDIO_FORMATS.mp3 },
	{ id: 3, name: AUDIO_FORMATS.wav },
	{ id: 14, name: AUDIO_FORMATS.aiff },
	{ id: 31, name: AUDIO_FORMATS.flac },
];

export const AUDIO_FORMAT_BITRATES = {
	mp3: "320kbps",
	wav: "44.1kHz, 16 bit",
	aiff: "44.1kKhz, 16 bit",
	flac: "44.1kHz, 16 bit",
};

const AUDIO_FORMAT_FEATURES_TRANSLATION_KEY = "Forms.AudioFormat.Features.";

export const AUDIO_FORMAT_FEATURES = {
	mp3: [
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.mp3}.1`,
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.mp3}.2`,
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.mp3}.3`,
	],
	wav: [
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.wav}.1`,
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.wav}.2`,
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.wav}.3`,
	],
	aiff: [
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.aiff}.1`,
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.aiff}.2`,
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.aiff}.3`,
	],
	flac: [
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.flac}.1`,
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.flac}.2`,
		`${AUDIO_FORMAT_FEATURES_TRANSLATION_KEY}${AUDIO_FORMATS.flac}.3`,
	],
};

export const BEATPORT_LOGO = {
	title: "Beatport",
	href: "",
	image: {
		path: "/images/beatport-logo.svg",
		width: 166,
		height: 49,
	},
	copyright: `© 2004-${CURRENT_YEAR} Beatport, LLC`,
	cypressTag: "footer-link-bplogo",
};

export const BROWSER_LOG_ROUTE = "/api/logs";

export const ONE_DAY_SECONDS = 24 * 3600 * 1000;

export const CHART_PLACEHOLDER = {
	title: "Chart",
	href: "https://geo-media.beatport.com/image_size/300x300/ab2d1d04-233d-4b08-8234-9782b34dcab8.jpg",
	image: {
		path: "/chart-placeholder.jpg",
		width: 262,
		height: 262,
	},
	cypressTag: "search-chartplaceholder",
};

export const convertToSlug = (string = "") =>
	string
		.replace(/[^\w ]+/g, "")
		.replace(/ +/g, "-")
		.toLowerCase();

export const DATE_FILTER_FORMAT = "yyyy-MM-dd";

export const DEFAULT_AUDIO_FORMAT = { id: 1, name: "MP3" };

export const DOWNLOAD_SUPPORT_URL =
	"https://support.beatport.com/hc/en-us/articles/4412980413716";

export const ENCODING_FORMATS = {
	mp3: "mp3",
	aiff: "aiff",
	wav: "wav",
	flac: "flac",
};

export const ENCODE_STATUS_POLL_RATE = 2000; // ms

export const GENRE_MENU_ITEMS = [
	{
		order: 0,
		links: [
			{
				label: "140 / Deep Dubstep / Grime",
				href: "/genre/140-deep-dubstep-grime/95",
				cypressTag: "header-subnav-link-genre95",
			},

			{
				label: "Afro House",
				href: "/genre/afro-house/89",
				cypressTag: "header-subnav-link-genre89",
			},
			{
				label: "Amapiano",
				href: "/genre/amapiano/98",
				cypressTag: "header-subnav-link-genre98",
			},
			{
				label: "Bass / Club",
				href: "/genre/bass-club/85",
				cypressTag: "header-subnav-link-genre85",
			},
			{
				label: "Bass House",
				href: "/genre/bass-house/91",
				cypressTag: "header-subnav-link-genre91",
			},
			{
				label: "Breaks / Breakbeat / UK Bass",
				href: "/genre/breaks-breakbeat-uk-bass/9",
				cypressTag: "header-subnav-link-genre9",
			},
			{
				label: "Dance / Electro Pop",
				href: "/genre/dance-electro-pop/39",
				cypressTag: "header-subnav-link-genre39",
			},
			{
				label: "Deep House",
				href: "/genre/deep-house/12",
				cypressTag: "header-subnav-link-genre12",
			},
		],
	},

	{
		order: 1,
		links: [
			{
				label: "DJ Tools",
				href: "/genre/dj-tools/16",
				cypressTag: "header-subnav-link-genre16",
			},
			{
				label: "Drum & Bass",
				href: "/genre/drum-bass/1",
				cypressTag: "header-subnav-link-genre1",
			},
			{
				label: "Dubstep",
				href: "/genre/dubstep/18",
				cypressTag: "header-subnav-link-genre18",
			},
			{
				label: "Electro (Classic / Detroit / Modern)",
				href: "/genre/electro-classic-detroit-modern/94",
				cypressTag: "header-subnav-link-genre94",
			},
			{
				label: "Electronica",
				href: "/genre/electronica/3",
				cypressTag: "header-subnav-link-genre3",
			},
			{
				label: "Funky House",
				href: "/genre/funky-house/81",
				cypressTag: "header-subnav-link-genre81",
			},
			{
				label: "Hard Dance / Hardcore",
				href: "/genre/hard-dance-hardcore/8",
				cypressTag: "header-subnav-link-genre8",
			},
			{
				label: "Hard Techno",
				href: "/genre/hard-techno/2",
				cypressTag: "header-subnav-link-genre2",
			},
		],
	},
	{
		order: 2,
		links: [
			{
				label: "House",
				href: "/genre/house/5",
				cypressTag: "header-subnav-link-genre5",
			},
			{
				label: "Indie Dance",
				href: "/genre/indie-dance/37",
				cypressTag: "header-subnav-link-genre37",
			},
			{
				label: "Jackin House",
				href: "/genre/jackin-house/97",
				cypressTag: "header-subnav-link-genre97",
			},
			{
				label: "Mainstage",
				href: "/genre/mainstage/96",
				cypressTag: "header-subnav-link-genre96",
			},
			{
				label: "Melodic House & Techno",
				href: "/genre/melodic-house-techno/90",
				cypressTag: "header-subnav-link-genre90",
			},
			{
				label: "Minimal / Deep Tech",
				href: "/genre/minimal-deep-tech/14",
				cypressTag: "header-subnav-link-genre14",
			},
			{
				label: "Nu Disco / Disco",
				href: "/genre/nu-disco-disco/50",
				cypressTag: "header-subnav-link-genre50",
			},
			{
				label: "Organic House / Downtempo",
				href: "/genre/organic-house-downtempo/93",
				cypressTag: "header-subnav-link-genre93",
			},
		],
	},
	{
		order: 3,
		links: [
			{
				label: "Progressive House",
				href: "/genre/progressive-house/15",
				cypressTag: "header-subnav-link-genre15",
			},
			{
				label: "Psy-Trance",
				href: "/genre/psy-trance/13",
				cypressTag: "header-subnav-link-genre13",
			},
			{
				label: "Tech House",
				href: "/genre/tech-house/11",
				cypressTag: "header-subnav-link-genre11",
			},
			{
				label: "Techno (Peak Time / Driving)",
				href: "/genre/techno-peak-time-driving/6",
				cypressTag: "header-subnav-link-genre6",
			},
			{
				label: "Techno (Raw / Deep / Hypnotic)",
				href: "/genre/techno-raw-deep-hypnotic/92",
				cypressTag: "header-subnav-link-genre92",
			},
			{
				label: "Trance",
				href: "/genre/trance/7",
				cypressTag: "header-subnav-link-genre7",
			},
			{
				label: "Trap / Wave",
				href: "/genre/trap-wave/38",
				cypressTag: "header-subnav-link-genre38",
			},
			{
				label: "UK Garage / Bassline",
				href: "/genre/uk-garage-bassline/86",
				cypressTag: "header-subnav-link-genre86",
			},
		],
	},
];

export const HEADER_MENU_ITEMS = [
	{
		order: 0,
		label: "Genres",
		subMenu: GENRE_MENU_ITEMS,
		cypressTag: "header-mainnav-link-genre",
	},
	{
		order: 1,
		label: "Dig Deeper",
		href: "/staff-picks",
		cypressTag: "header-mainnav-link-staffpicks",
	},
	{
		order: 2,
		label: "Beatport DJ",
		href: "https://dj.beatport.com/",
		newWindow: true,
		cypressTag: "header-mainnav-link-djapp",
	},
	{
		order: 3,
		label: "Beatportal",
		href: "https://www.beatportal.com/",
		newWindow: true,
		cypressTag: "header-mainnav-link-beatport",
	},
	{
		order: 4,
		label: "Beatport Studio",
		href: "https://www.pluginboutique.com/beatport-studio",
		newWindow: true,
		cypressTag: "header-mainnav-link-beatport-studio",
	},
	{
		order: 5,
		label: "Beatport.io",
		href: "https://beatport.io",
		newWindow: true,
		cypressTag: "header-mainnav-link-beatport-io",
	},
];

export const LABEL_PLACEHOLDER = {
	title: "Label.Title",
	href: "https://geo-media.beatport.com/image_size/500x500/cda9862c-cf92-4d13-ac65-7e9277181f51.jpg",
	image: {
		path: "/label-placeholder.jpg",
		width: 200,
		height: 200,
	},
	cypressTag: "search-labelplaceholder",
};
export const nextAuthRefreshInterval = 35995; // api4 is 36000 minutes - refresh this 5 min prior to expiration

export const PURCHASE_TYPE_IDS = {
	purchase: 1,
	upgrade: 2,
	preorder: 3,
};

export const PURCHASE_TYPE_TYPES: { [key: number]: string } = {
	1: "item_purchase",
	2: "lossless_upgrade",
	3: "pre_order",
};

export const ITEM_TYPE_IDS = {
	track: 1,
	release: 2,
};

export const RELEASE_PLACEHOLDER = {
	title: "Release",
	href: "https://geo-media.beatport.com/image_size/250x250/ab2d1d04-233d-4b08-8234-9782b34dcab8.jpg",
	image: {
		path: "/release-placeholder.jpg",
		width: 200,
		height: 200,
	},
	cypressTag: "search-releaseplaceholder",
};

export const SIDEBAR_LINKS: SidebarLinkProps[] = [
	{
		label: "MyBeatport",
		icon: SidebarIconKey.Heart,
		href: "/my-beatport",
		cypressTag: "sidebar-nav-my-beatport",
		requireAuth: true,
	},
	{
		label: "Collection.label",
		icon: SidebarIconKey.Collection,
		href: "/library",
		cypressTag: "sidebar-nav-collection",
		requireAuth: true,
	},
	{
		label: "Downloads.Title",
		icon: SidebarIconKey.Downloads,
		href: "/library/downloads",
		cypressTag: "sidebar-nav-downloads",
		requireAuth: true,
	},
	{
		label: "Playlists.Title",
		icon: SidebarIconKey.Playlists,
		href: "/library/playlists",
		cypressTag: "sidebar-nav-playlists",
		requireAuth: true,
	},
];

export const TRACK_PLACEHOLDER = {
	title: "Track",
	href: "https://geo-media.beatport.com/image_size/95x95/ab2d1d04-233d-4b08-8234-9782b34dcab8.jpg",
	image: {
		path: "/track-placeholder.jpg",
		width: 38,
		height: 38,
	},
	cypressTag: "search-trackplaceholder",
};

export const urlPayload = (params: any) => new URLSearchParams(params);

export const ENCODING_STATUSES = {
	COMPLETE: "COMPLETE",
	ENCODING: "ENCODING",
	ERROR: "ERROR",
};

export const MOBILE_APP_LINKS: (ImageProps & Pick<LinkProps, "href">)[] =
	[{
		src: "/images/apple-store.svg",
		alt: "",
		width: "120",
		height: "40",
		href: "https://apps.apple.com/us/app/beatport-music-for-djs/id1543739988",
	},
	{
		src: "/images/google-store.svg",
		alt: "",
		width: "135",
		height: "40",
		href: "https://play.google.com/store/apps/details?id=com.beatport.mobile&hl=en",
	}];
