import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { Control } from "@components/interaction/Play/Play.style";
import {
	Artwork,
	Exclusive,
	Item,
	ItemButtons,
	ItemControls,
	ItemControlsContainer,
	ItemDataContainer,
	ItemDataWrapper,
	ItemImageContainer,
	ItemImageWrapper,
	ItemName,
	ItemNumber,
} from "@components/lists/Lists.shared.style";
import { DeleteConfirmationModal } from "@components/modals/DeleteConfirmationModal";
import { ArtistNames, renderArtistNames } from "@components/shared/Artists/ArtistNames";
import { usePlayerActions, usePlayerState } from "@lib/context/player";
import { cls } from "@lib/css";
import { Track } from "@models/track";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

export const ChartsTrackListItem: React.FC<{
	track: Track;
	index: number;
	chartDeleteTrack?: (trackId: number) => void;
	dragHandleProps?: DraggableProvidedDragHandleProps;
	location?: string;
}> = ({ track, index, chartDeleteTrack, dragHandleProps, location }) => {
	const { currentTrack } = usePlayerState();
	const { playTrack, addTracks } = usePlayerActions();
	const { t } = useTranslation("translation");
	const exclusive = `${t("Exclusive")}`;

	const handlePlayTrackClick = (currTrack: Track) => {
		playTrack(currTrack);
		setTimeout(() => addTracks([currTrack]), 100);
	};

	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
		useState(false);

	const handleDeleteTrack = (trackid: number) => {
		if (!chartDeleteTrack) {
			return;
		}
		chartDeleteTrack(trackid);
		setShowDeleteConfirmationModal(false);
	};

	return (
		<>
			<DeleteConfirmationModal
				show={showDeleteConfirmationModal}
				header={t("Charts.MyDjCharts.Modal.DeleteTrackHeader")}
				confirmation={t("Charts.MyDjCharts.Modal.DeleteTrackConfirm")}
				onClose={() => setShowDeleteConfirmationModal(false)}
				onDelete={() => handleDeleteTrack(track.id)}
			/>
			<Item
				className={cls(
					currentTrack && currentTrack.id === track.id ? "current" : undefined,
				)}
			>
				<ItemImageWrapper>
					<ItemImageContainer>
						<ItemNumber>{index + 1}</ItemNumber>
						<Control onClick={() => handlePlayTrackClick(track)}>
							<Artwork>
								<Image
									src={track.release.image?.uri}
									alt={track.release.name}
									width={80}
									height={80}
								/>
								{track.exclusive && <Exclusive>{exclusive.toUpperCase()}</Exclusive>}
							</Artwork>
						</Control>
					</ItemImageContainer>
				</ItemImageWrapper>
				<ItemDataWrapper>
					<ItemDataContainer>
						<Link
							href={`/track/${track.slug}/${track.id}`}
							prefetch={false}
							title={track.name}
							style={{
								display: "block",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							<ItemName>
								{track.name} <span>{track.mix_name}</span>{" "}
							</ItemName>
						</Link>
						{track.artists?.length > 0 && (
							<ArtistNames>{renderArtistNames(track.artists, { location })}</ArtistNames>
						)}
					</ItemDataContainer>
					<ItemControlsContainer>
						<ItemControls>
							<ItemButtons className="borderless">
								{dragHandleProps && (
									<button {...dragHandleProps}>
										<SpriteIcon id="re-order" />
									</button>
								)}
								<button onClick={() => setShowDeleteConfirmationModal(true)}>
									<SpriteIcon id="close-x" />
								</button>
							</ItemButtons>
						</ItemControls>
					</ItemControlsContainer>
				</ItemDataWrapper>
			</Item>
		</>
	);
};
