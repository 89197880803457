export const SYMBOL_MAP: Record<string, string> = {
	AUD: "AU$",
	BRL: "R$",
	EUR: "€",
	GBP: "£",
	MXN: "MX$",
	USD: "$",
};

export const TRIMMED_CURRENCIES = [SYMBOL_MAP.MXN];
export const ZER0_DECIMALS = ".00";

export const DEFAULT_CURRENCY = "USD";
export const DEFAULT_COUNTRY = "US";
