import { PER_PAGE_OPTIONS } from "@components/paging/Pager/Pager";
import { API_BASES, ENCODING_FORMATS, PURCHASE_TYPE_IDS, PURCHASE_TYPE_TYPES } from "@lib/constants";
import { PLANS } from "@lib/constants/subscriptions";
import { AUTH_TOKEN_URL, CONTENT_TYPES } from "@lib/constants/urls";
import {
	getEncodeStatusQuery,
	postOnDemandEncodeRequest,
} from "@lib/network/library";
import { postRequest } from "@lib/network/request";
import { IntrospectUser } from "@models/auth";
import { Track } from "@models/track";
import { QueryClient } from "@tanstack/react-query";
import { getConfig } from "config";
import { Session } from "next-auth";
import { DownloadError } from "./errors";
import { DEFAULT_CURRENCY, TRIMMED_CURRENCIES, ZER0_DECIMALS } from "@lib/constants/currency";
import { Audioformat } from "@models/audio-format";
import { CartItem } from "@models/Cart";
import { Release } from "@models/release";

export const buildGetEncodeStatusQueries = (
	{ formatDict, queryClient, accessToken }: { formatDict: Record<string, Track[]>; queryClient: QueryClient; accessToken: string },
) => {
	const queries: Promise<any>[] = [];
	for (const format in formatDict) {
		const trackIds = formatDict[format].map((t) => t.id);
		if (!trackIds.length) {
			// no tracks in this format: skip
			continue;
		}

		const query = async () => {
			const status = await queryClient
				.fetchQuery(
					getEncodeStatusQuery({
						params: {
							audio_format: format,
							track_ids: trackIds.join(","),
							per_page: trackIds.length,
						},
						accessToken,
					}),
				)
				.then((res) => res)
				.catch(() => { });

			if (
				tracksUnencoded(status.results || [])
			) {
				// TODO: is the default axios error handling good enough here?
				await postOnDemandEncodeRequest({
					data: {
						tracks: trackIds,
						audio_format: format,
					},
					accessToken,
				});
			}

			return status;
		};

		queries.push(query());
	}
	return queries;
};

export const checkIfUserHasSubscription = (
	user: IntrospectUser | undefined,
	planPartialString?: string,
) => {
	if (!user || !user.subscription) {
		return false;
	}

	const hasValidSubscription = Object.values(PLANS).includes(user.subscription);

	if (planPartialString) {
		return (
			hasValidSubscription && user.subscription.includes(planPartialString)
		);
	}

	return hasValidSubscription;
};

export const checkIsMobile = () => {
	return /iPad|iPhone|iPod|Android/.test(navigator.userAgent);
};

/**
 * Generates a filename with the correct track meta data in the requested pattern & extension
 * @param {Track}   track - Track Object
 * @param {string}  extension - File extension
 * @param {string}  pattern - File naming convention pattern
 * @returns {String} Filename in the requested pattern and extension
 */
export const createFilename = (
	{ track, extension, pattern }: { track: Track; extension: string; pattern: string },
) => {
	// Find strings (keywords) inside {}
	const keywordMatcher = /{([^}]*)}/g;
	const matches = pattern.match(keywordMatcher);

	let patternNoDl = pattern;
	let delimiter = " ";

	// Find any instances of _-.SPACE
	const hasDelimiter = pattern.search(/[_\-. ]/g) === 0;
	if (hasDelimiter) {
		[delimiter, patternNoDl] = [pattern.slice(0, 1), pattern.slice(1)];
	}

	// Replace keywords with track meta if key exists
	const processedFilename = matches
		?.reduce((str: string, match: string) => {
			const key = match.replace(/[{}]/g, "") as keyof Track;
			const realStr = track[key] !== null ? processFilenameKey(key, track) : "";
			const re = new RegExp(match, "g");
			return str.replace(re, realStr);
		}, patternNoDl)
		.substring(0, 255)
		.replace(/ /g, delimiter);
	return `${processedFilename}.${extension}`;
};

export const extractPriceFromString = (price: string, symbol: string) => Number(price.split(symbol)[1].split(" ")[0]);

export const getFilenameDate = () => {
	const today = new Date();
	const dd = String(today.getDate()).padStart(2, "0");
	const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
	const yyyy = String(today.getFullYear());
	return { dd, mm, yyyy };
};

export const getLocalStorage = (key: string) => {
	const isClient = typeof window !== "undefined";
	if (!isClient) return;
	const value = localStorage.getItem(key);
	return value && JSON.parse(value);
};

export const getSafeDefaultPageParam = (page: string | string[] | undefined): number => isNaN(Number(page)) ? 1 : Number(page);

export const getSafeDefaultPerPageParam = (perPage: string | string[] | undefined, defaultPerPage: number): number => isNaN(Number(perPage)) ? defaultPerPage : Math.min(Number(perPage), PER_PAGE_OPTIONS[PER_PAGE_OPTIONS.length - 1]);

export const getStringParam = (param: string | string[] | undefined): string | undefined => Array.isArray(param) ? param[0] : param;

export const getServerSideToken = async (accessToken: string) => {
	if (accessToken === "") {
		const payload = {
			grant_type: "client_credentials",
			client_id: getConfig().API4_CLIENT_ID,
			client_secret: getConfig().API4_CLIENT_SECRET,
		};

		const response = await postRequest({
			url: AUTH_TOKEN_URL,
			apiBase: API_BASES.api4,
			accessToken,
			contentType: CONTENT_TYPES.formUrlEncoded,
			data: new URLSearchParams(payload).toString(),
		})
			.then((res) => res.data)
			.catch((err) => console.error(err));
		return response?.access_token || "";
	}
	return accessToken;
};

/**
 * @description Take a time in milliseconds and convert to ##:##
 * @param {Number} ms time in milliseconds
 * @param {Boolean} pad if true, a leading 0 will be added to minutes values less than 10 (e.g. 9 becomes 09)
 * @returns {String} Formatted time
 */
export const msToClock = (ms: number, pad = false) => {
	if (typeof ms !== "number" || Number.isNaN(ms)) {
		return (pad ? "0" : "") + "0:00";
	}

	const minutes = Math.floor(ms / 60000);
	const seconds = Math.floor((ms % 60000) / 1000);

	return `${(pad && minutes < 10 ? "0" : "") + minutes}:${(seconds < 10 ? "0" : "") + seconds
	}`;
};

export const processFilenameKey = (key: string | keyof Track, track: Track) => {
	const id = String(track.id);
	const bpm = String(track.bpm);
	switch (key) {
		case "artists":
		case "remixers":
			return track[key].map((k: { name: any }) => k.name).join(", ");
		case "genre":
		case "key":
			return track[key].name;
		case "release_day":
			return track.publish_date.split("-")[2];
		case "release_month":
			return track.publish_date.split("-")[1];
		case "release_year":
			return track.publish_date.split("-")[0];
		case "purchase_day":
			return track.purchase_date?.split("T")[0].split("-")[2] || id;
		case "purchase_month":
			return track.purchase_date?.split("T")[0].split("-")[1] || id;
		case "purchase_year":
			return track.purchase_date?.split("T")[0].split("-")[0] || id;
		case "download_day":
			return getFilenameDate().dd;
		case "download_month":
			return getFilenameDate().mm;
		case "download_year":
			return getFilenameDate().yyyy;
		case "track_name":
			return track.name;
		case "mix_name":
			return track.mix_name;
		case "track_id":
			return id;
		case "label":
			return track.release.label.name;
		case "bpm":
			return bpm;
		default:
			return id;
	}
};

export const redirectToLogin = (session: Session | null, url?: string) => {
	if (!session || (session && session.token.anon)) {
		const redirectUrl = "/account/login";
		return {
			redirect: {
				permanent: false,
				destination: url ? `${redirectUrl}?next=${url}` : redirectUrl,
			},
		};
	}
};

export const redirectToURL = (url: string) => {
	window.location.href = url;
};

export const removeLocalStorage = (key: string) => {
	localStorage.removeItem(key);
};

export const setLocalStorage = (key: string, value: any) => {
	localStorage.setItem(key, JSON.stringify(value));
};

export const sliceArrayByPage = <T>({ data, page, perPage }: { data: T[]; page: number; perPage: number }) => (data?.slice(
	(page - 1) * perPage,
	page * perPage,
));

export const slugify = (...args: (string | number)[]): string => {
	const value = args.join(" ");

	return value
		.normalize("NFD") // split an accented letter in the base letter and the acent
		.replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
		.toLowerCase()
		.trim()
		.replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
		.replace(/\s+/g, "-"); // separator
};

export const tracksByAudioFormat = (
	tracks: Track[],
): Record<string, Track[]> => ({
	mp3: tracks.filter((t) => t.audio_format?.name === ENCODING_FORMATS.mp3),
	aiff: tracks.filter((t) => t.audio_format?.name === ENCODING_FORMATS.aiff),
	wav: tracks.filter((t) => t.audio_format?.name === ENCODING_FORMATS.wav),
	flac: tracks.filter((t) => t.audio_format?.name === ENCODING_FORMATS.flac),
});

export const tracksUnencoded = (
	encodeStatuses: { encode_status_name: string; track_id: number }[],
) => {
	if (!encodeStatuses) return false;
	return (
		encodeStatuses.filter(
			(e: any) =>
				e.encode_status_name !== "COMPLETE" && e.encode_status_name !== "ERROR",
		).length > 0
	);
};

export const triggerSingleFileDownload = async (
	filename: string,
	downloadUrl: string,
) => {
	try {
		const response = await fetch(downloadUrl);
		const blob = await response.blob();
		const blobUrl = URL.createObjectURL(blob);
		const link = document.createElement("a");
		document.body.appendChild(link);
		link.setAttribute("href", blobUrl);
		link.setAttribute("download", filename);
		link.setAttribute("target", "_blank");
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(blobUrl);
	} catch {
		throw new DownloadError();
	}
};

export const updateSelectedTracks = (
	{ isChecked, isCollection, track, queueTracks, selectedTracks }: { isChecked: boolean; isCollection: boolean; track: Track; queueTracks: Track[]; selectedTracks: Track[] },
) => {
	const updatedTracks = isChecked ?
		isCollection ?
				[...queueTracks, track] :
				[...selectedTracks, track] :
		isCollection ?
				queueTracks.filter((selectedTrack: any) => selectedTrack.id !== track.id) :
				selectedTracks.filter((selectedTrack) => selectedTrack.id !== track.id);

	return updatedTracks;
};

export const getCurrencyFromPlan = (plansData?: Plan[]) => {
	const planPrice = plansData?.[0]?.plan_price;
	const currency = !!planPrice ? Object.keys(planPrice)[0] : DEFAULT_CURRENCY;

	return currency;
};

export const getItemPrice = ({
	item,
	audioFormatsObject,
	returnWithSymbol = true,
}: {
	item: CartItem<Track | Release>;
	audioFormatsObject: Record<number, Audioformat>;
	returnWithSymbol?: boolean;

}) => {
	const itemAudioFormat = item.audio_format_id;
	const itemPriceSymbol = item.item.price?.symbol || "";
	const itemValue = item.item.price?.value || 0;
	const itemPriceFees = audioFormatsObject[itemAudioFormat]?.fees;
	const itemPurchaseId = item.purchase_type_id;
	const purchaseTypeKey = PURCHASE_TYPE_TYPES[itemPurchaseId];
	const losslessUpgradePurchaseType = PURCHASE_TYPE_TYPES[PURCHASE_TYPE_IDS.upgrade];

	const track_count = item.item_type_id === 2 && "track_count" in item.item ? item.item.track_count : 1;

	const itemBase = purchaseTypeKey !== losslessUpgradePurchaseType ? itemValue : 0;
	const itemFee = itemPriceFees?.[purchaseTypeKey]?.value * track_count || 0;

	if (returnWithSymbol) {
		return removeZeroDecimals(itemPriceSymbol + (itemBase + itemFee).toFixed(2));
	} else {
		return itemBase + itemFee;
	}
};

// Removes .00 from price, i.e MX$29.00 -> MX$29
export const removeZeroDecimals = (price: string = "") => {
	if (price.endsWith(ZER0_DECIMALS)) {
		for (const currency of TRIMMED_CURRENCIES) {
			if (price.startsWith(currency)) {
				return price.slice(0, -3);
			}
		};
	}

	return price;
};
