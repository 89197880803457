import { Wrapper } from "@components/tables/Tables.shared.style";
import styled from "styled-components";

export const LibraryTableWrapper = styled(Wrapper)`
  .add-tracks-controls {
    padding-left: 0;
    width: 2.5rem;
    min-width: 2.5rem;
    padding: 0rem;
  }

  .label {
    width: 12.5rem;
    min-width: 12.5rem;
  }

  .date {
    width: 7.5rem;
    min-width: 7.5rem;
  }

  .bpm {
    width: 9.38rem;
    min-width: 9.38rem;
  }

  .genre {
    width: 100%;

    > a > span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      white-space: pre-wrap;
    }
  }

  .download-actions {
    display: flex;
    gap: 1.25rem;
    text-transform: none;
  }

  span.tombstoned {
    opacity: 0.15;
  }
`;

export const TrackAddIndicator = styled.div`
  span.arrow {
    background-color: rgb(0, 0, 0, 0);
    border-radius: 0px 0.25rem 0.25rem 0px;
    padding: 0.25rem;
    margin-right: 1.94rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    i {
      margin: 0.25rem;
      display: inline-block;
      vertical-align: middle;

      border-left: 0.25rem solid transparent;
      border-right: 0.25rem solid transparent;
      border-top: 0.25rem solid
        ${(props) => props.theme.colors.neutrals.primary.white};
    }

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;
