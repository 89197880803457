import { CartEvent } from "@bptypes/dataLayerEvents";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { ItemLoader } from "@components/loaders";
import { RePurchaseTracksModal } from "@components/modals/RePurchaseTracksModal";
import { postMessage } from "@components/notifications";
import { DEFAULT_AUDIO_FORMAT } from "@lib/constants";
import { DEFAULT_CURRENCY } from "@lib/constants/currency";
import { useCart, useCartActions } from "@lib/context/cart";
import { LayoutContext } from "@lib/context/layout/LayoutContext";
import { useSessionContext } from "@lib/context/session";
import { cls } from "@lib/css";
import { getTrackRequest } from "@lib/network/tracks";
import { pushAddToCartEvent } from "@lib/utils/dataLayer";
import { CartDetails, PurchaseItem } from "@models/Cart";
import { Chart } from "@models/Chart";
import { Playlist } from "@models/Playlists";
import { Artist } from "@models/track";
import { useTranslation } from "next-i18next";
import { useContext, useEffect, useState } from "react";
import { Control, CustomChildWrapper } from "./AddToCart.style";
import { AddRemoveItem, AddRemoveRelease, AddRemoveTrack, AddToCartProps } from "./AddToCart.types";
import { removeZeroDecimals } from "@lib/utils";

const AddToCart: React.FC<AddToCartProps> = ({
	track,
	trackId,
	release,
	chart,
	playlist,
	iconOnly = false,
	location = "",
	children = null,
	tracks,
	isMyPlaylist = false,
}) => {
	const { authenticateLink, showCartSelector } = useContext(LayoutContext);
	const { getAccessToken, getIsAnon } = useSessionContext();
	const { t } = useTranslation("translation");
	const accessToken = getAccessToken();
	const isAnon = getIsAnon();
	const [loading, setLoading] = useState(false);
	const { state, setCartSelectorDisabled } = useCart();
	const { carts, cartDetails, loading: cartsLoading } = state;
	const [repurchaseTracks, setRepurchaseTracks] = useState<PurchaseItem[]>([]);
	const [areAllTracksInCart, setAreAllTracksInCart] = useState<boolean>(false);
	const [repurchaseCart, setRepurchaseCart] = useState<number>(0);
	const {
		removeItemFromCart,
		addTrackToCart,
		addReleaseToCart,
		addChartToCart,
		addPlaylistToCart,
		createCartQueries,
		purchaseItems,
	} = useCartActions();

	const { isTrackInCart, isReleaseInCart, getDefaultCart, getCartById } =
		createCartQueries(carts, cartDetails);

	const [pendingAction, setPendingAction] = useState<string[]>([]);

	let price = "N/A";
	let isInCart: { inCart: boolean; cartIds: number[] } = {
		inCart: false,
		cartIds: [],
	};
	let itemType = "";
	if (track) {
		itemType = "track";
		price = track.price?.display || price;
		isInCart = isTrackInCart(track.id);
	} else if (release) {
		itemType = "release";
		price = release.price?.display || price;
		isInCart = isReleaseInCart(release.id);
	} else if (chart) {
		itemType = "chart";
		price = chart.price?.display || price;
	} else if (playlist) {
		itemType = "playlist";
		price = t("BuyAll");
	}

	price = removeZeroDecimals(price);

	const isPreOrder = track ? track.pre_order : release?.pre_order || false;

	// sale type id 4 is albumOnly
	const isAlbumOnly =
		track && track.sale_type && track.sale_type.name === "albumOnly";

	const isMixOnly =
		track && track.sale_type && track.sale_type.name === "mixOnly";

	const addPlaylist = async (cart: CartDetails, playlist: Playlist) => {
		const response = await addPlaylistToCart({
			cartId: cart.id,
			playlistId: playlist.id,
			isMyPlaylist,
		});

		if (response.message === "This cart has reached its limit of 150 items.") {
			return {
				success: response.success,
				message: response.message,
			};
		}
		const tracksObject = response.data.success === true ? response.data.data.errors : response.data.data.validation_errors;

		// check if added chart tracks were previously purchased?
		const alreadyPurchased = Object.keys(tracksObject).filter((t: any) => tracksObject[t] === "already purchased");
		const tracksToRepurchase = await Promise.all(alreadyPurchased
			.map(async (trackId) => {
				const trackData = await getTrack(Number(trackId));
				return {
					id: trackData?.id,
					type: "1",
					name: trackData?.name,
					audio_format: trackData?.audio_format || DEFAULT_AUDIO_FORMAT.id,
					mix_name: trackData?.mix_name,
				};
			})
			.filter((track) => track !== undefined));

		if (tracksToRepurchase.length > 0) {
			setRepurchaseTracks(tracksToRepurchase);
			setRepurchaseCart(cart.id);
		}

		const products = tracks?.map((track, index) => ({
			id: track.id.toString(),
			label: track.release?.label?.name || "",
			category: "Tracks",
			name: track.name,
			variant: "track",
			type: "product",
			list: location,
			price: track.price?.value || 0,
			quantity: 1,
			position: (cart.tracks?.length || 0) + index,
			artist: track.artists?.map((a) => a.name).join(", ") || "",
			genre: track.genre?.name || "",
		})) || [];

		const addToCartEvent: CartEvent = {
			ecommerce: {
				currency: cart.summary?.price.cart_subtotal?.code || DEFAULT_CURRENCY,
				add: {
					actionField: {
						list: location,
					},
					products,
				},
			},
		};

		pushAddToCartEvent(addToCartEvent);

		const message = `${t("Playlist")} ${t("Tracks")} ${t("Cart.AddedToCart")}`;
		const errorMessage = t("Error.UnableAddPlaylistTracks");

		if (response.data.success && tracksToRepurchase.length === 0) setAreAllTracksInCart(true);

		return {
			success: response.data.success,
			message: response.data.success ? message : errorMessage,
		};
	};

	const addChart = async (cart: CartDetails, chart: Chart) => {
		const audioFormatId = undefined;
		const purchaseTypeId = undefined;
		const sourceTypeId = 6;

		const response = await addChartToCart(
			cart.id,
			chart.id,
			audioFormatId,
			purchaseTypeId,
			sourceTypeId,
		);

		if (response.message === "This cart has reached its limit of 150 items.") {
			return {
				success: response.success,
				message: response.message,
			};
		}
		const tracksObject = response.data.success === true ? response.data.data.errors : response.data.data.validation_errors;

		// check if added chart tracks were previously purchased?
		const alreadyPurchased = Object.keys(tracksObject).filter((t: any) => tracksObject[t] === "already purchased");
		const tracksToRepurchase = await Promise.all(alreadyPurchased
			.map(async (trackId) => {
				const trackData = await getTrack(Number(trackId));
				return trackData;
			})
			.filter((track) => track !== undefined));

		const formattedTracks = tracksToRepurchase.map((track) => {
			return {
				id: track.id,
				type: "1",
				name: track.name || "",
				audio_format: track.audio_format || DEFAULT_AUDIO_FORMAT.id,
				mix_name: track.mix_name,
			};
		});

		if (formattedTracks.length > 0) {
			setRepurchaseTracks(formattedTracks);
			setRepurchaseCart(cart.id);
		}

		const products = tracksToRepurchase.map((track, index) => ({
			id: track.id.toString(),
			label: track.release?.label?.name || "",
			category: "Tracks",
			name: track.name,
			variant: "track",
			type: "product",
			list: location,
			price: track.price?.value || 0,
			quantity: 1,
			position: (cart.tracks?.length || 0) + index,
			artist: track.artists?.map((a: Artist) => a.name).join(", ") || "",
			genre: track.genre?.name || "",
		}));

		const addToCartEvent: CartEvent = {
			ecommerce: {
				currency: cart.summary?.price.cart_subtotal?.code || DEFAULT_CURRENCY,
				add: {
					actionField: {
						list: location,
					},
					products,
				},
			},
		};

		pushAddToCartEvent(addToCartEvent);

		const message = `${t("Charts.label")} ${t("Tracks")} ${t("Cart.AddedToCart")}`;
		const errorMessage = t("Error.UnableAddChartTracks");

		if (response.data.success && tracksToRepurchase.length === 0) setAreAllTracksInCart(true);

		return {
			success: response.data.success,
			message: response.data.success ? message : errorMessage,
		};
	};

	const addRemoveTrack: AddRemoveTrack = async (cart, track) => {
		const audioFormatId = undefined;
		const purchaseTypeId = undefined;
		const sourceTypeId = 6;

		const isIncurrentCart =
			cart.tracks?.some((item) => item.item_id === track.id) || false;

		let cartItemId = 0;
		if (isIncurrentCart) {
			cart.tracks?.forEach((t) => {
				if (t.item_id == track.id) {
					cartItemId = t.id;
				}
			});

			const data = await removeItemFromCart(cart.id, cartItemId);
			const message = `${track.name} ${track.mix_name} ${t("Cart.RemovedFromCart")}`;

			return {
				success: data.success,
				message: data.success ? message : data.message,
				notificationType: "neutral",
			};
		} else {
			const data = await addTrackToCart({
				cartId: cart.id,
				track,
				audioFormatId,
				purchaseTypeId,
				sourceTypeId,
			});

			if (!data.success && data.data.AlreadyPurchased) {
				setRepurchaseCart(cart.id);
				setRepurchaseTracks([data.data.AlreadyPurchased]);
				return {
					success: data.success,
					message: "",
				};
			}

			const message = `${track.name} ${track.mix_name} ${t("Cart.AddedToCart")}`;

			const product = {
				id: track.id.toString(),
				type: "product",
				name: track.name,
				label: track.release?.label?.name || "",
				category: "Tracks",
				variant: "track",
				list: location,
				price: track.price?.value || 0,
				quantity: 1,
				position: cart.tracks?.length || 0,
				artist: track.artists?.map((a) => a.name).join(", ") || "",
				genre: track.genre?.name || "",
			};

			const addToCartEvent: CartEvent = {
				ecommerce: {
					currency: cart.summary?.price.cart_subtotal?.code || DEFAULT_CURRENCY,
					add: {
						actionField: {
							list: location,
						},
						products: [product],
					},
				},
			};

			pushAddToCartEvent(addToCartEvent);

			return {
				success: data.success,
				message: data.success ? message : data.message,
			};
		}
	};

	const addRemoveRelease: AddRemoveRelease = async (cart, release) => {
		const audioFormatId = undefined;
		const purchaseTypeId = undefined;
		const sourceTypeId = 6;

		const isIncurrentCart =
			cart.releases?.some((item) => item.item_id === release.id) || false;

		let cartItemId = 0;
		if (isIncurrentCart) {
			cart.releases?.forEach((t) => {
				if (t.item_id == release.id) {
					cartItemId = t.id;
				}
			});

			const data = await removeItemFromCart(cart.id, cartItemId);
			const message = `${release.name} ${t("Cart.RemovedFromCart")}`;

			return {
				success: data.success,
				message: data.success ? message : data.message,
				notificationType: "neutral",
			};
		} else {
			const data = await addReleaseToCart({
				cartId: cart.id,
				release,
				audioFormatId,
				purchaseTypeId,
				sourceTypeId,
			});

			if (!data.success && data.data.AlreadyPurchased) {
				setRepurchaseCart(cart.id);
				setRepurchaseTracks([data.data.AlreadyPurchased]);
				return {
					success: data.success,
					message: "",
				};
			}

			const message = `${release.name} ${t("Cart.AddedToCart")}`;

			const addToCartEvent: CartEvent = {
				ecommerce: {
					currency: cart.summary?.price.cart_subtotal?.code || DEFAULT_CURRENCY,
					add: {
						actionField: {
							list: location,
						},
						products: [
							{
								id: release.id.toString(),
								type: "product",
								name: release.name,
								label: release.label?.name || "",
								category: "Releases",
								variant: "release",
								list: location,
								price: release.price?.value || 0,
								quantity: 1,
								position: cart.releases?.length || 0,
								artist: release.artists?.map((a) => a.name).join(", ") || "",
								genre: release.genres?.map((g) => g.name).join(", ") || "",
							},
						],
					},
				},
			};

			pushAddToCartEvent(addToCartEvent);

			return {
				success: data.success,
				message: data.success ? message : data.message,
			};
		}
	};

	const getTrack = async (trackId: number) => {
		try {
			const { data: trackData } = await getTrackRequest({ id: trackId, accessToken });
			return trackData;
		} catch {
			return null;
		}
	};

	const addRemoveItem: AddRemoveItem = async (
		cartId,
		defaultCart,
	) => {
		const cart = defaultCart ?
			defaultCart :
			cartId ?
					getCartById(cartId) :
					getDefaultCart();

		if (cart) {
			if (chart) {
				return addChart(cart, chart);
			} else if (track) {
				return addRemoveTrack(cart, track);
			} else if (release) {
				return addRemoveRelease(cart, release);
			} else if (playlist) {
				return addPlaylist(cart, playlist);
			} else if (trackId) {
				setLoading(true);
				const trackData = await getTrack(trackId);
				setLoading(false);

				if (!trackData) {
					return {
						success: false,
						message: "Track not found",
					};
				} else {
					return addRemoveTrack(cart, trackData);
				}
			} else {
				return {
					success: false,
					message: t("Cart.UnsupportedItem"),
				};
			}
		}
		return {
			success: false,
			message: t("Cart.NotExist"),
		};
	};

	const handleOnClick = async () => {
		if (isAnon) {
			authenticateLink("", () => {
				setLoading(true);
				setPendingAction(["add-remove"]);
			});
		} else {
			setLoading(true);
			addRemoveItem().then(({ success, message, notificationType }) => {
				if (message !== "") {
					postMessage({
						type: success ? notificationType || "success" : "error",
						message: message,
					});
				}
				setLoading(false);
			});
		}
	};

	const showCartPicker = async (e: React.MouseEvent<HTMLSpanElement>) => {
		if (
			itemType !== "track" &&
			itemType !== "release" &&
			itemType !== "chart" &&
			itemType !== "playlist"
		)
			return;
		if (isAnon) {
			authenticateLink("", () => {
				// dont do anything :D
			});
		} else {
			const left = e.clientX + 10;
			const top = e.clientY - 15;
			showCartSelector({
				show: true,
				top,
				left,
				carts: Object.values(carts) || [],
				selectedCarts: isInCart.cartIds,
				onSelectedCart: (cartId: number) => {
					setLoading(true);
					setCartSelectorDisabled(true);
					addRemoveItem(cartId).then(({ success, message, notificationType }) => {
						if (message !== "") {
							postMessage({
								type: success ? notificationType || "success" : "error",
								message: message,
							});
						}
						setLoading(false);
						setCartSelectorDisabled(false);
					});
				},
			});
		}
	};

	const handleOnRePurchaseItems = async (items: PurchaseItem[]) => {
		setLoading(true);
		await purchaseItems(repurchaseCart, items);

		let message = t("Cart.ItemSuccess");
		if (items.length === 1) {
			const item = items[0];
			const type = item.type === "1" ? "Track" : "Release";
			message = `${type} ${item.name} ${item.mix_name} ${t("Cart.SuccessAddedToCart")}`;
		}

		setAreAllTracksInCart(true);

		postMessage({
			type: "success",
			message: message,
		});
		setRepurchaseTracks([]);
		setLoading(false);
	};

	useEffect(() => {
		if (pendingAction.length > 0 && !cartsLoading) {
			pendingAction.forEach((action) => {
				if (action === "add-remove") {
					addRemoveItem().then(({ success, message, notificationType }) => {
						if (message !== "") {
							postMessage({
								type: success ? notificationType || "success" : "error",
								message: message,
							});
						}
						setLoading(false);
					});
				}
			});
			setPendingAction([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cartsLoading, pendingAction]);

	if (children) {
		return (
			<CustomChildWrapper
				onClick={handleOnClick}
				disabled={loading}
				isLoading={loading}
			>
				{loading && <ItemLoader />}
				{children}
			</CustomChildWrapper>
		);
	}

	return (
		<>
			<Control
				className={cls(
					isPreOrder ? "preorder" : undefined,
					isAlbumOnly ? "album-only" : undefined,
					isMixOnly ? "album-only" : undefined,
					isInCart.inCart || areAllTracksInCart ? "in-cart" : undefined,
					iconOnly ? "icon-only" : undefined,
					"add-to-cart",
				)}
				disabled={isAlbumOnly || (cartsLoading && !isAnon)}
			>
				{cartsLoading && !isAnon ?
						(
							<>
								<span className="price">
									{iconOnly ? <SpriteIcon id="basket" /> : price}
								</span>

								<span className="arrow">
									<ItemLoader />
								</span>
							</>
						) :
					isAlbumOnly || isMixOnly ?
							(
								<span className="album">
									{isMixOnly ? "Mix Only" : ""} {isAlbumOnly ? "Release Only" : ""}
								</span>
							) :
							(
								<>
									<span className="price" onClick={handleOnClick}>
										{iconOnly ?
												(
													<SpriteIcon id="basket" />
												) :
											isInCart.inCart || areAllTracksInCart ?
													(
														"In cart"
													) :

												price}
									</span>
									<span className="arrow" onClick={showCartPicker}>
										{loading ? <ItemLoader /> : <i />}
									</span>
								</>
							)}
			</Control>
			<RePurchaseTracksModal
				loading={loading}
				items={repurchaseTracks}
				show={repurchaseTracks.length > 0}
				onClose={() => setRepurchaseTracks([])}
				onRePurchaseItems={handleOnRePurchaseItems}
			/>
		</>
	);
};

export default AddToCart;
