import { LinkData, SocialMediaLink } from "@bptypes/links";
import {
	InternalUrls,
	NetworkUrls,
	SocialMediaUrls,
} from "@lib/constants/links";

export const INTERNAL_LINKS: LinkData[] = [
	{
		label: "Footer.AboutBeatport",
		href: InternalUrls.ABOUT,
		newWindow: true,
	},
	{
		label: "Footer.CustomerSupport",
		href: InternalUrls.CUSTOMER_SUPPORT,
		newWindow: true,
	},
	{
		label: "Footer.ContactUs",
		href: InternalUrls.CONTACT_US,
		newWindow: true,
	},
	{
		label: "Footer.Careers",
		href: InternalUrls.CAREERS,
		newWindow: true,
	},
	{
		label: "Footer.LogosAndImages",
		href: InternalUrls.LOGOS,
		newWindow: true,
	},
	{
		label: "Footer.TermsAndConditions",
		href: InternalUrls.TERMS_AND_CONDITIONS,
		newWindow: true,
	},
	{
		label: "Footer.PrivacyPolicy",
		href: InternalUrls.PRIVACY_POLICY,
		newWindow: true,
	},
	{
		label: "Footer.CopyrightInfringement",
		href: InternalUrls.COPYRIGHT_INFRINGEMENT,
		newWindow: true,
	},
];

export const NETWORK_LINKS: LinkData[] = [
	{
		label: "ampsuite Distribution",
		href: NetworkUrls.AMPSUITE,
		newWindow: true,
	},
	{
		label: "Beatportal",
		href: NetworkUrls.BEATPORTAL,
		newWindow: true,
	},

	{
		label: "Beatport DJ",
		href: NetworkUrls.BEATPORT_DJ,
		newWindow: true,
	},
	{
		label: "Beatport Hype",
		href: NetworkUrls.BEATPORT_HYPE,
		newWindow: true,
	},
	{
		label: "Beatport for Labels",
		href: NetworkUrls.BEATPORT_LABEL,
		newWindow: true,
	},
	{
		label: "Beatport Next",
		href: NetworkUrls.BEATPORT_NEXT,
		newWindow: true,
	},
	{
		label: "Beatport Streaming",
		href: NetworkUrls.BEATPORT_STREAMING,
		newWindow: true,
	},
	{
		label: "LabelRadar",
		href: NetworkUrls.LABEL_RADAR,
		newWindow: true,
	},
	{
		label: "Loopcloud",
		href: NetworkUrls.LOOPCLOUD,
		newWindow: true,
	},
	{
		label: "Plugin Boutique",
		href: NetworkUrls.PLUGIN_BOUTIQUE,
		newWindow: true,
	},
];

export const SOCIAL_LINKS: SocialMediaLink[] = [
	{
		title: "Facebook",
		href: SocialMediaUrls.FACEBOOK,
		icon: {
			path: "facebook-footer",
			width: 24,
			height: 20,
		},
	},
	{
		title: "Twitter",
		href: SocialMediaUrls.TWITTER,
		icon: {
			path: "twitter-footer",
			width: 24,
			height: 20,
		},
	},
	{
		title: "Instagram",
		href: SocialMediaUrls.INSTAGRAM,
		icon: {
			path: "instagram",
			width: 24,
			height: 20,
		},
	},
	{
		title: "Tiktok",
		href: SocialMediaUrls.TIKTOK,
		icon: {
			path: "tiktok",
			width: 24,
			height: 20,
		},
	},
	{
		title: "Youtube",
		href: SocialMediaUrls.YOUTUBE,
		icon: {
			path: "youtube",
			width: 24,
			height: 20,
		},
	},
	{
		title: "Discord",
		href: SocialMediaUrls.DISCORD,
		icon: {
			path: "discord",
			width: 24,
			height: 20,
		},
	},
];
