import { Trans } from "next-i18next";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Validator, ValidatorFn, validationMethods } from "../Validators";
import { Description, ErrorMessage, SelectWrapper } from "./Select.style";

interface Props extends React.HTMLProps<HTMLSelectElement> {
	id: string;
	name: string;
	defaultValue?: string | number | readonly string[] | undefined;
	defaultOption?: { name: string; value: string | number };
	options: Array<{ name: string; value: string | number }>;
	onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
	errors?: string[];
	description?: string;
	validators?: (Validator | ValidatorFn)[];
	className?: string;
}

const Select = React.memo<Props>(
	({
		id,
		name,
		defaultValue,
		defaultOption,
		options,
		validators,
		errors,
		description,
		className,
		onChange,
		...rest
	}) => {
		const selectRef = useRef<HTMLSelectElement>(null);

		const [validationMessages, setValidationMessages] = useState<string[]>(
			errors ? errors : [],
		);

		useEffect(() => {
			if (errors) {
				setValidationMessages([...errors]);
			}
		}, [errors]);

		const onBlur = (e: FormEvent) => {
			const target = e.target as HTMLInputElement;

			if (validators) {
				setValidationMessages([]);
				validators.every(async (validator) => {
					let func: ValidatorFn = async () => ({
						valid: true,
						message: "",
					});
					switch (typeof validator) {
						case "function":
							func = validator;
							break;
						case "string":
							func = validationMethods[validator];
							break;
						default:
							return;
					}
					const validation = await func(target.name, target.value);
					if (!validation.valid) {
						setValidationMessages((m) => [...m, validation.message]);
					}
				});
			}

			if (validationMessages.length === 0 && target.validationMessage) {
				setValidationMessages((m) => [...m, target.validationMessage]);
			}
		};

		const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
			onChange?.(e);
		};

		const isDefaultOptionSelected = [undefined, defaultOption?.value].includes(selectRef?.current?.value);
		const selectClassName = isDefaultOptionSelected ? "placeholder" : "";

		return (
			<SelectWrapper className={className}>
				<select
					id={id}
					name={name}
					onBlur={onBlur}
					onChange={handleOnChange}
					defaultValue={defaultValue}
					ref={selectRef}
					className={selectClassName}
					{...rest}
				>
					{defaultOption && (
						<option value={defaultOption.value} className="italic">{defaultOption.name}</option>
					)}
					{options.map((opt, index) => (
						<option key={`${opt.value}-${index}`} value={opt.value}>
							<Trans>{opt.name}</Trans>
						</option>
					))}
				</select>
				{validationMessages.length > 0 && (
					<ErrorMessage>{validationMessages[0]}</ErrorMessage>
				)}
				{!!description && <Description>{description}</Description>}
			</SelectWrapper>
		);
	},
);

Select.displayName = "Select";

export default Select;
